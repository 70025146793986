import { AddressService } from "@/services/AddressService.js";

export const state = () => ({
  List: [],
  idAddressDelivery: 0,
  idAddressInvoice: 0,
});

export const mutations = {
  setAll(state, { data }) {
    state.List = data;
  },
  setIdAddressDelivery(state, idAddress) {
    state.idAddressDelivery = idAddress;
  },
  setIdAddressInvoice(state, idAddress) {
    state.idAddressInvoice = idAddress;
  },
};

export const actions = {
  fetchAddresses({ commit }, { onSuccess = () => {}, onError = () => {} }) {
    const addressService = new AddressService({
      http: this.$http,
      auth: this.$auth,
    });

    addressService
      .getAddresses()
      .then((data) => {
        commit("setAll", { data });
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
};
