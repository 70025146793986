import { FavoriteService } from "@/services/FavoriteService.js";

export const state = () => ({
  IdFavorite: null,
  TotalProduct: 0,
  Products: [],
});

export const mutations = {
  setAll(state, { data }) {
    state.IdFavorite = data.IdFavorite;
    state.TotalProduct = data.TotalProduct;
    state.Products = data.Products;
  },
  setSummary(state, { data }) {
    state.IdFavorite = data.IdFavorite;
    state.TotalProduct = data.TotalProduct;
  },
  clear(state) {
    state.IdFavorite = null;
    state.TotalProduct = 0;
    state.Products = [];
  },
};

export const actions = {
  fetchFavoriteSummary(
    { commit },
    { onSuccess = () => {}, onError = () => {} } = {}
  ) {
    const favoriteService = new FavoriteService({
      http: this.$http,
      auth: this.$auth,
    });

    favoriteService
      .getFavoriteSummary({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        commit("setSummary", { data });
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
  fetchFavorite({ commit }, { onSuccess = () => {}, onError = () => {} }) {
    const favoriteService = new FavoriteService({
      http: this.$http,
      auth: this.$auth,
    });

    favoriteService
      .getFavoriteAll({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        if (data.IdFavorite) {
          commit("setAll", { data });
        }
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
};
