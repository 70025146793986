export const state = () => ({
  pageCount: 0,
  showed: false
})

const NEWSLETTER_PAGE_COUNT = "NEWSLETTER_PAGE_COUNT"
const NEWSLETTER_SHOWED = "NEWSLETTER_SHOWED"


const setDataToCoockies = (cookies, keyName, value) => {
  cookies.set(keyName, value, {
    path: "/",
    maxAge: 60 * 60 * 24 * 7,
  });
}


export const mutations = {



  setPageCount(state, {
    $cookies,
    val
  }) {
    const cookies = $cookies;
    setDataToCoockies(cookies, NEWSLETTER_PAGE_COUNT, val)
    state.pageCount = val
  },

  setIsShowed(state, {
    $cookies,
    val
  }) {
    const cookies = $cookies;
    setDataToCoockies(cookies, NEWSLETTER_SHOWED, val)
    state.showed = val
  },

  init(state, $cookies) {
    const cookies = $cookies;
    let pageCount = cookies.get(NEWSLETTER_PAGE_COUNT);
    let showed = cookies.get(NEWSLETTER_SHOWED);

    if (pageCount !== undefined) {
      state.pageCount = pageCount;
    }

    if (showed !== undefined) {
      state.showed = showed;
    }

  },
}
