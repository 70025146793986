export default {
  computed: {
    hasTopBanner() {
      try {
        if (
          this.$SETTINGS.isMobile &&
          this.$store.state.shared.topBanner.alwaysDisable
        ) {
          return false;
        }
        return this.$store.state.shared.topBanner.active;
      } catch (error) {}

      return false;
    },
  },
};
