import { CartService } from "../../services/CartService";

export const state = () => ({
  data: {},
  errors: [],
});

export const mutations = {
  setPromoCode(state, { data }) {
    state.errors = [];
    state.data = data;
  },
  setErrors(state, { error }) {
    state.errors = error;
  },
};

export const actions = {
  async apply(
    { commit },
    { code, idCart, loyaltyRewardStateLabel, onSuccess, onError }
  ) {
    const cartService = new CartService({
      http: this.$http,
      auth: this.$auth,
      store: this,
    });

    try {
      const data = await cartService.applyPromoCode({
        code: code,
        idCart: idCart,
        loyaltyRewardStateLabel,
      });
      commit("setPromoCode", { data });
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      commit("setErrors", { error: [error] });
      if (onError) {
        onError(error);
      }
    }
  },
};
