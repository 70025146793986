const setDataToCoockies = (cookies, keyName, value) => {
  cookies.set(keyName, value, {
    path: '/',
    maxAge: 60 * 60 * 2,
  });
};

const removeDataFromCoockies = (cookies, keyName) => {
  cookies.remove(keyName);
};

const GUEST_USER = 'GUEST_USER';

export const state = () => ({
  guestUser: null,
  emailGuest: '',
});

export const mutations = {
  SET_GUEST_USER(state, { user, cookies }) {
    if (user) {
      state.guestUser = user;
      // localStorage.setItem('guestUser', JSON.stringify(user));
      setDataToCoockies(cookies, GUEST_USER, user);
    } else {
      removeDataFromCoockies(cookies, GUEST_USER);
      state.guestUser = null;
      state.emailGuest = '';
    }
  },
  LOAD_GUEST_USER(state, { cookies }) {
    const guestUser = cookies.get(GUEST_USER);
    state.guestUser = guestUser;
  },
  SET_GUEST_EMAIL(state, email) {
    state.emailGuest = email;
  },
};

export const actions = {
  setGuestUser({ commit }, options) {
    commit('SET_GUEST_USER', options);
  },
  loadGuestUser({ commit }, options) {
    commit('LOAD_GUEST_USER', options);
  },
  setEmailGuest({ commit }, email) {
    commit('SET_GUEST_EMAIL', email);
  },
};

export const getters = {
  guestUser: (state) => state.guestUser,
  emailGuest: (state) => state.emailGuest,
};
