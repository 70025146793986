export const state = () => ({
  localisationDialogState: false,
  languages: [],
  countries: [],
  currencies: ["USD", "EUR", "CHF", "GBP"],
  appLanguage: null,
  appCountry: null,
  appCurrency: null,
  countries: [
    {
      IdCountry: "0",
      CountryName: "France",

      // IdCountry: process.env.COUNTRY_ID ? process.env.COUNTRY_ID : "0",
      // CountryName: process.env.COUNTRY_NAME ? process.env.COUNTRY_NAME : "France",
      CountryCurrencies: [
        // {
        //   IdCurrency: "1",
        //   CurrencyName: "Euro",
        //   CountryIsoCode: "FRA",
        //   CurrencyIsoCode: "EUR",
        //   CountryIsoCodeAlpha2: "FR",
        //   CurrencySign: "\u20ac",
        //   CurrencyConversionRate: "1.00",
        //   IsMainCurrency: false,
        // },
        // {
        //   IdCurrency: "3",
        //   CurrencyName: "Dollar Am\u00e9ricain",
        //   CurrencyIsoCode: "USD",
        //   CurrencySign: "$",
        //   CurrencyConversionRate: "0.98",
        //   IsMainCurrency: false,
        // },
      ],

      DefaultLanguageName: "Fran\u00e7ais",
      DefaultIdLang: "1",
      //LanguageIsoCode: "fr",

      // DefaultLanguageName: process.env.LANG_NAME ? process.env.LANG_NAME :"Fran\u00e7ais",
      // DefaultIdLang: process.env.LANG_ID ? process.env.LANG_ID  : "1",
      
      LanguageIsoCode: process.env.INIT_LANG_ISOCODE ? process.env.INIT_LANG_ISOCODE : "fr",
      
      
      Tax: {
        TaxName: "TVA",
        IdTax: "1",
        TaxRate: "0.2",
      },
    },
  ],
});

const APP_LANGUE = "APP__LANGUE";
const APP_COUNTRY = "APP__COUNTRY";
const APP_CURRENCY = "APP__CURRENCY";

const setDataToCoockies = (cookies, keyName, value) => {
  cookies.set(keyName, value, {
    path: "/",
    maxAge: 60 * 60 * 24 * 365,
  });
};

export const mutations = {
  toggleDialog(state, val) {
    state.localisationDialogState = val;
  },

  setLanguages(state, val) {
    state.languages = val;
  },

  setCountries(state, val) {
    state.countries = val;
  },

  setAppCountry(state, { $auth, val }) {
    state.appCountry = val;
    $auth.$storage.setLocalStorage(APP_COUNTRY, state.appCountry);

    try {
      let appCountry = $auth.$storage.getLocalStorage(APP_COUNTRY);
    } catch (error) {}
  },

  setAppCurrency(state, { $auth, val }) {
    state.appCurrency = val;
    $auth.$storage.setLocalStorage(APP_CURRENCY, state.appCurrency);
  },

  initialiseStore(state, $auth) {
    try {
      let appCountry = $auth.$storage.getLocalStorage(APP_COUNTRY);
      
      let appCurrency = $auth.$storage.getLocalStorage(APP_CURRENCY);

      if (appCountry === undefined) {
        state.appCountry = null;
      } else {
        state.appCountry = appCountry;
      }
      if (appCurrency === undefined) {
        state.appCurrency = null;
      } else {
        state.appCurrency = appCurrency;
      }
    } catch (error) {
    }
  },
};
