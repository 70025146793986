import Vue from 'vue'
import DatePicker from "element-ui"
import VueI18n from 'vue-i18n'

import enLocale from 'element-ui/lib/locale/lang/en'
import frLocale from 'element-ui/lib/locale/lang/fr'
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)
Vue.use(DatePicker)

const messages = {
  en: {
    ...enLocale
  },
  fr: {
    ...frLocale
  }
}
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'fr', // set locale
  messages, // set locale messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))
