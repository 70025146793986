export class ShopHelper {
  constructor(http, context, lang = 2) {
    this.context = context;
    this.http = http;
    if (lang === 'fr') {
      this.lang = 1;
    } else if (lang === 'de') {
      this.lang = 3;
    } else {
      this.lang = 2;
    }
  }

  async newsletterSubscription({ email, languageIsoCode, currencyIsoCode }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    formData.append('LanguageIsoCode', languageIsoCode);
    formData.append('CurrencyIsoCode', currencyIsoCode);
    let httpApi = this.http.API;

    if (this.context.$auth.loggedIn) {
      httpApi = this.http.API_AUTH;
    }

    try {
      const response = await httpApi.post(`newsletter/subscribe`, formData);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      return error;
    }
  }

  async newsletterUnSubscription({ email }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    let httpApi = this.http.API;

    if (this.context.$auth.loggedIn) {
      httpApi = this.http.API_AUTH;
    }

    try {
      const response = await httpApi.post(`newsletter/unsubscribe`, formData);
      if (response.status === 200) {
        return response;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async setReturnsReasons({ languageIsoCode }) {
    try {
      const res = await this.http.API_AUTH.get(
        `order/return/reasons?LanguageIsoCode=${languageIsoCode}`
      );
      if (res.status === 200) {
        const { data } = res;

        this.context.$store.commit('shop/returns/setReasons', data?.Reasons);
        return data;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async backInStock({ email, idProduct, idProductAttribute, languageIsoCode }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    formData.append('IdProduct', idProduct);
    formData.append('IdProductAttribute', idProductAttribute);
    formData.append('LanguageIsoCode', languageIsoCode);

    if (this.context.$auth.loggedIn) {
      formData.append('IdCustomer', this.context.$auth.user.Customer.Id);
    }
    try {
      const response = await this.http.API.post(
        `campaign/backInStock`,
        formData
      );
      if (response.status === 200) {
        return response;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async cancelingVisit({ email, idCart }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    formData.append('IdCart', idCart);
    let httpApi = this.http.API;

    if (this.context.$auth.loggedIn) {
      httpApi = this.http.API_AUTH;
    }

    try {
      const response = await httpApi.post(`/cart/abandoned`, formData);
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
}
