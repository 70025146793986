export class Amount {
  #_sum = 0;
  #_tax = 0;
  #_ctx;
  #_fixedNumberBy = 2;
  constructor(ctx) {
    this.#_ctx = ctx;
  }

  set fixedNumber(val) {
    this.#_fixedNumberBy = val;
  }
  toFixed(val) {
    return Number(Number(val).toFixed(this.#_fixedNumberBy));
  }

  price(sum, taxRate = null) {
    this.#_sum = Number(sum);
    this.#_tax = taxRate;

    return {
      euroHT: this.#euroHT,
      euroTTC: this.#euroTTC,
      taxRate: this.#taxRate,
      changeRate: this.#changeRate,
      ht: this.#ht,
      ttc: this.#ttc,
    };
  }

  get #euroHT() {
    return this.toFixed(this.#_sum);
  }

  get #euroTTC() {
    const val = this.#_sum + this.#_sum * this.#taxRate;
    return this.toFixed(val);
  }

  get convertionRate() {
    return Number(this.#_ctx.$localisation.appCountry.Tax.TaxRate);
  }
  get currency() {
    return this.#_ctx.$localisation.appCurrency;
  }

  get #taxRate() {
    if (this.#_tax !== null) {
      return Number(this.#_tax);
    }
    const taxRate = this.convertionRate;

    return taxRate;
  }

  get #changeRate() {
    const rate = Number(this.currency.CurrencyConversionRate);
    return rate;
  }

  get #ht() {
    const priceHTWithCurrency = Number(this.#_ctx.$toCurrency(this.#_sum));
    return this.toFixed(priceHTWithCurrency);
  }

  get #ttc() {
    const priceHTWithCurrency = Number(this.#_ctx.$toCurrency(this.#_sum));
    const priceTTCWithCurrency =
      priceHTWithCurrency + priceHTWithCurrency * this.#taxRate;

    return this.toFixed(priceTTCWithCurrency);
  }

  toPriceHt(val) {
    return Number((val / (1 + this.convertionRate)).toFixed(2));
  }
}
