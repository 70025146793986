import { CartService } from "@/services/CartService.js";

export default {
  data() {
    return {
      cartService: new CartService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
    };
  },
  computed: {
    cart() {
      return this.cartService?.cart;
    },
  },
  methods: {
    openRightBarCart() {
      this.$store.commit("shared/menu/toggle", {
        tabActive: 2,
        open: true,
      });
    },
  },
  
};
