import { ProductHelpers } from "@/helpers/ProductHelpers.js";

export const state = () => ({
  currentProduct: null,
});

export const mutations = {
  setProduct(state, { product }) {
    state.currentProduct = product;
  },

  init(state) {
    state.currentProduct = null;
  },
};

export const actions = {
  async fetchProduct({ commit, state }, { permalink, currencyIsoCode }) {
    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    try {
      const response = await productHelpers.getProductV2({
        permalink,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode,
      });
      if (response.status === 200) {
        const product = response.data;
        commit("setProduct", { product });
      } else {
        // You may want to handle other status codes differently
        throw new Error(`Unexpected status code: ${response.status}`);
      }
      return response;
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  },
};
