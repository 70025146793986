export const state = () => ({
  isMobile: true,
  isTablette: true,
  loginGoTo: "/account/informations",
  loading: false,
  abandonedVisit: false
})

export const mutations = {
  toggle(state) {
    state.isMobile = window.innerWidth < 768;
    state.isTablette = window.innerWidth < 1024;
  },
  setLoginGoTo(state, link) {
    state.loginGoTo = link
  },
  setIsLoading(state, val) {
    if (val) {
      document.querySelector('body').classList.add('preloading')
    } else {
      document.querySelector('body').classList.remove('preloading')
    }
    state.loading = val
  },
  toggleAbandonedVisit(state, val) {
    state.abandonedVisit = val
  }
}
