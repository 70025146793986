import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster"; // replace src with dist if you have Babel issues

export default function (ctx) {
  Vue.component("GmapCluster", GmapCluster);
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.GOOGLE_MAP_KEY,
      libraries: "places", // This is required if you use the Autocomplete plugin
      // OR: libraries: 'places,drawing'
      // OR: libraries: 'places,drawing,visualization'
      // (as you require)

      //// If you want to set the version, you can do so:
      // v: '3.26',

      language: ctx.i18n.locale,
    },

  });
}
