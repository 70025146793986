export const state = () => ({
  menuState: false,
  isTransparent: false,
  tabActive: 2,
  acountSideBarActive: "",
  sticky: false,
  alwaysSticky: false
});

export const mutations = {
  toggle(state, va) {
    state.tabActive = va.tabActive;
    state.menuState = va.open;
  },

  setIsTransparent(state, va) {
    state.isTransparent = va;
  },

  setAcountSideBarActive(state, va) {
    state.acountSideBarActive = va;
  },

  setMenuSticky(state, va) {
    state.sticky = va;
  },
  setAlwaysMenuSticky(state, va) {
    state.alwaysSticky = va;
  },
};
