export class LoyaltyService {
  constructor(http) {
    this.http = http;
  }

  async loyaltyCustomer({ languageIsoCode, currencyIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `loyalty/customer?LanguageIsoCode=${languageIsoCode}&CurrencyIsoCode=${currencyIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
  async loyaltyHistory({ languageIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `loyalty/history?LanguageIsoCode=${languageIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async subscribe({ birthdayDate, languageIsoCode }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('CustomerBirthday', birthdayDate);
    formData.append('LanguageIsoCode', languageIsoCode);
    formData.append('LoyaltyCustomerState', true);
    formData.append('IdLoyaltyProgram', 4);

    try {
      const response = await this.http.API_AUTH.post(
        'loyalty/subscribe',
        formData
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async unsubscribe({ languageIsoCode }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('LanguageIsoCode', languageIsoCode);

    try {
      const response = await this.http.API_AUTH.post(
        'loyalty/unsubscribe',
        formData
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async addGoogleWallet() {
    try {
      const response = await this.http.API_AUTH.get('loyalty/wallet/google');
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
  async getListEvents({ languageIsoCode, currencyIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `loyalty/event/list?IdLoyaltyProgram=4&LanguageIsoCode=${languageIsoCode}&CurrencyIsoCode=${currencyIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async getListRewards({ languageIsoCode, currencyIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `loyalty/reward?IdLoyaltyProgram=4&LanguageIsoCode=${languageIsoCode}&CurrencyIsoCode=${currencyIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async getListMyRewards({
    languageIsoCode,
    currencyIsoCode,
    loyaltyRewardState = 'all',
  }) {
    try {
      const response = await this.http.API_AUTH.get(
        `loyalty/reward/1/customerReward?IdLoyaltyProgram=4&LanguageIsoCode=${languageIsoCode}&CurrencyIsoCode=${currencyIsoCode}&LoyaltyRewardState=${loyaltyRewardState}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
  async obtainReward({ idLoyaltyReward, languageIsoCode }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();
      formData.append('LanguageIsoCode', languageIsoCode);
      formData.append('IdLoyaltyReward', idLoyaltyReward);
      formData.append('IdLoyaltyProgram', 4);

      const response = await this.http.API_AUTH.post(
        `loyalty/reward/1/obtain`,
        formData
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
  async obtainEvent({ idLoyaltyProgramEvent, languageIsoCode }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();
      formData.append('LanguageIsoCode', languageIsoCode);
      formData.append('IdLoyaltyProgramEvent', idLoyaltyProgramEvent);
      formData.append('IdLoyaltyProgram', 4);

      const response = await this.http.API_AUTH.post(
        `loyalty/event/obtain`,
        formData
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async loyaltyTier({ IdLoyaltyProgram, LanguageIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `loyalty/tier/list?IdLoyaltyProgram=${IdLoyaltyProgram}&LanguageIsoCode=${LanguageIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
}
