export const state = () => ({
  products: [],
});

const LAST_PRODUCT_VISITED = "LAST_PRODUCTS_VISITED";

export const mutations = {
  addProduct(state, { product, $auth }) {
    const maxProduct = 6;
    const index = state.products.findIndex((item) => {
      try {
        return item.IdProduct === product.IdProduct;
      } catch (error) {}

      return false
    });

    if (index !== -1) {
      state.products.splice(index, 1);
    }

    if (state.products.length >= maxProduct) {
      state.products.splice(maxProduct - 1, 1);
    }
    if (typeof product === "object" && product !== null) {
      state.products.unshift(product);
    }

    $auth.$storage.setLocalStorage(LAST_PRODUCT_VISITED, state.products);
  },
  init(state, { $auth }) {
    const products = $auth.$storage.getLocalStorage(LAST_PRODUCT_VISITED);
    if (products) {
      state.products = products;
    } else {
      $auth.$storage.removeLocalStorage(LAST_PRODUCT_VISITED);
    }
  },
};
