export const state = () => ({
  body: null,
  link: null,
  active: false,
  alwaysDisable: false
});

export const mutations = {
  init(state, { body, link, active }) {
    state.body = body;
    state.link = link;
    state.active = active;
  },
  setAlwaysDisable(state, va) {
    state.alwaysDisable = va;
  },
};
