import { LoyaltyService } from "@/helpers/LoyaltyService.js";

export const state = () => ({
  customerEvents: {},
  listRewards: [],
  customer: null,
  myRewards: {},
});

export const mutations = {
  setCustomerEvents(state, { customerEvents }) {
    state.customerEvents = customerEvents;
  },
  setRewards(state, { listRewards }) {
    state.listRewards = listRewards;
  },
  setLoyaltyCustomer(state, { customer }) {
    state.customer = customer;
  },
  setMyRewards(state, { myRewards }) {
    state.myRewards = myRewards;
  },
};
export const actions = {
  async fetchEvents({ state, commit }) {
    const loyaltyService = new LoyaltyService(this.$http);
    try {
      const response = await loyaltyService.getListEvents({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      });
      commit("setCustomerEvents", { customerEvents: response });
    } catch (error) {}
  },
  async fetchRewards({ state, commit }) {
    const loyaltyService = new LoyaltyService(this.$http);
    try {
      const response = await loyaltyService.getListRewards({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      });
      commit("setRewards", { listRewards: response.LoyaltyRewards });
    } catch (error) {}
  },
  async fetchMyRewards({ commit }, { loyaltyRewardState }) {
    const loyaltyService = new LoyaltyService(this.$http);
    try {
      const response = await loyaltyService.getListMyRewards({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        loyaltyRewardState
      });
      commit("setMyRewards", { myRewards: response });
    } catch (error) {
    }
  },
  async fetchLoyaltyCustomer({ commit }, { onSuccess = () => {} } = {}) {
    const loyaltyService = new LoyaltyService(this.$http);

    try {
      const response = await loyaltyService.loyaltyCustomer({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      });
      commit("setLoyaltyCustomer", { customer: response });
      onSuccess();
    } catch (error) {
    }
  },
};
