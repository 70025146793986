
export default function (context) {

  try {

    context.store.commit("popups/newsletter/init", context.$cookies);
    const newsletter = context.store.state.popups.newsletter;

    if (!newsletter.showed) {
      context.store.commit("popups/newsletter/setPageCount", {
        $cookies: context.$cookies,
        val: newsletter.pageCount + 1,
      });
    }



  } catch (error) {
    console.error(error);
  }

}
