export const state = () => ({
  cardInstance: null,
  errors: []
})


export const mutations = {
  setCardInstance(state, cardInstance) {
    state.cardInstance = cardInstance;
  },
  setErrors(state, errors) {
    state.errors = errors;
  }
}
