export default function ({
  store,
  redirect
}) {
  // If the user is not authenticated
  //   try {
  //     if ($auth.$storage.getUniversal("USER_CONNECTED").Authentification) {
  //       return redirect("/account/informations");
  //     } else {
  //       return redirect("/authenticate/login");
  //     }
  //   } catch (error) {
  //     return redirect("/authenticate/login");
  //   }
  if (store.state.password.email === "") {
    return redirect("/authenticate/login");
  }
}
