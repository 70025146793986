export const state = () => ({
  reasons: [],
});

export const mutations = {
  setReasons(state, reasons) {
    if (reasons) {
      state.reasons = reasons;
    }
  },
};
