export default function ({
  $auth,
  redirect
}) {
  // If the user is not authenticated
  //   try {
  //     if ($auth.$storage.getUniversal("USER_CONNECTED").Authentification) {
  //       return redirect("/account/informations");
  //     } else {
  //       return redirect("/authenticate/login");
  //     }
  //   } catch (error) {
  //     return redirect("/authenticate/login");
  //   }
  if ($auth.loggedIn) {
    return redirect("/account/informations");
  }
}
