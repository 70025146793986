//add product to cart

export class CartHelper {
  // constructor(context, openMiniCart = true) {

  //   this.session = context.$session;
  //   this.http = context.$http;
  //   this.store = context.$store;
  //   this.openMiniCart = openMiniCart;

  // }

  constructor(http, store, auth, openMiniCart = true) {
    this.http = http;
    this.store = store;
    this.auth = auth;
    this.openMiniCart = openMiniCart;
  }

  clearCart() {
    this.store.commit("shop/cart/clear", this.auth);
  }

  async initNewCart() {
    try {
      let res = await this.http.API_AUTH.$get("customer/cart/newCart");
      if (res.IdCart) {
        return res;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async updateCartId() {
    try {
      let res = await this.http.API_AUTH.$post("cart/updateId");
      if (res.NewIdCart) {
        return res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  setTypeCart(type) {
    this.store.commit("shop/cart/setTypeCart", {
      $auth: this.auth,
      type,
    });
  }

  setProductToSession(res, increment) {
    if (res.IdCart) {
      this.store.commit("shop/cart/add", {
        $auth: this.auth,
        item: {
          idCartDetail: res.CartDetail.IdCartDetail,
          product: {
            IdProduct: res.CartDetail.IdProduct,
            Reference: res.CartDetail.Reference,
            ProductDescription: res.CartDetail.ProductDescription,
            ProductImageUrl: res.CartDetail.ProductImageUrl
              ? res.CartDetail.ProductImageUrl
              : "",
            Prices: res.CartDetail.Prices[0],
            EAN13: res.CartDetail.EAN13,
            IdProductAttribute: res.CartDetail.IdProductAttribute,
            SEO: res.CartDetail.SEO,
          },
          quantity: !increment ? res.CartDetail.Quantity : 1,
          color: res.CartDetail.Color,
          size: res.CartDetail.Size,
        },
        idCart: res.IdCart,
        increment: increment,
        cartTotal: res.CartTotal,
      });
      if (this.openMiniCart) {
        this.store.commit("shared/menu/toggle", {
          tabActive: 2,
          open: true,
        });
      }
    }
  }

  async addProductToCartGuest(
    { product, color, size, quantity, idProductAttribute },
    increment = true
  ) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("IdProduct", product.IdProduct);
    formData.append("Reference", product.Reference);
    formData.append("Color", color);
    formData.append("Size", size);
    formData.append("Quantity", quantity);
    formData.append("IdProductAttribute", idProductAttribute);

    let idCart = this.store.state.shop.cart.idCart;
    if (idCart != null) {
      formData.append("IdCart", idCart);
    }
    if (!increment) {
      formData.append("Increment", "0");
    }
    try {
      let res = await this.http.API.$post("addProductToGuestCart", formData);

      // this.setProductToSession(res, increment);
      this.setCartItemsLogin(res);
      if (this.openMiniCart) {
        this.store.commit("shared/menu/toggle", {
          tabActive: 2,
          open: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async addProductToCart(
    { product, quantity, idProductAttribute },
    increment = true
  ) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("IdProduct", product.IdProduct);
    formData.append("Quantity", quantity);
    formData.append("IdProductAttribute", idProductAttribute);

    if (!increment) {
      formData.append("Increment", "0");
    }

    try {
      let res = await this.http.API_AUTH.$post("cart/product/add", formData);
      // this.setProductToSession(res, increment);
      this.setCartItemsLogin(res);
      if (this.openMiniCart) {
        this.store.commit("shared/menu/toggle", {
          tabActive: 2,
          open: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  setCartItemsLogin(cart) {
    this.clearCart();
    cart.CartDetail.forEach((item) => {
      this.store.commit("shop/cart/add", {
        $auth: this.auth,
        item: {
          idCartDetail: item.IdCartDetail,
          product: {
            IdProduct: item.IdProduct,
            Reference: item.Reference,
            ProductDescription: item.ProductDescription,
            ProductImageUrl: item.ProductImageUrl,
            Prices: item.Prices[0],
            AvailableSizes: item.AvailableSizes,
            EAN13: item.EAN13,
            IdProductAttribute: item.IdProductAttribute,
            SEO: item.SEO,
          },
          quantity: item.Quantity,
          color: item.Color,
          size: item.Size,
        },
      });
    });

    this.store.commit("shop/cart/setIdCart", {
      $auth: this.auth,
      idCart: cart.IdCart,
    });

    this.store.commit("shop/cart/setCartTotal", {
      $auth: this.auth,
      cartTotal: cart.CartTotal,
    });
  }

  async setSessionCartClient() {
    try {
      // let res = await this.http.API_AUTH.$get("customerDataByToken");

      // if (res.CartDetail) {
      //   this.setCartItemsLogin(res);
      // }

      if (this.auth.user.CartDetail) {
        this.setCartItemsLogin(this.auth.user);
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async setSessionCartGuest() {
    let idCart = this.store.state.shop.cart.idCart;
    if (idCart) {
      try {
        let res = await this.http.API.$get(
          `listGuestCartProducts?IdCart=${idCart}`
        );
        if (res.status === "success") {
          this.setCartItemsLogin(res);
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.clearCart();
        }
      }
    }
  }

  async removeProductClient(idCartDetail) {
    const FormData = require("form-data");
    const formData = new FormData();
    formData.append("IdCartDetail", idCartDetail);

    const res = await this.http.API_AUTH.$post(
      "deleteProductFromCart",
      formData
    );

    try {
      if (res.status === "success") {
        this.store.commit("shop/cart/remove", {
          $auth: this.auth,
          idCartDetail: idCartDetail,
          cartTotal: res.CartTotal,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async removeProductGuest(idCartDetail) {
    let idCart = this.store.state.shop.cart.idCart;

    const FormData = require("form-data");
    const formData = new FormData();
    formData.append("IdCartDetail", idCartDetail);
    formData.append("IdCart", idCart);

    const res = await this.http.API.$post(
      "deleteProductFromGuestCart",
      formData
    );
    try {
      if (res.status === "success") {
        this.store.commit("shop/cart/remove", {
          $auth: this.auth,
          idCartDetail: idCartDetail,
          cartTotal: res.CartTotal,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async setProductSize(idCartDetail, size) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("IdCartDetail", idCartDetail);
    formData.append("Size", size);
    let res = null;
    try {
      if (this.auth.loggedIn) {
        res = await this.http.API_AUTH.$post(
          "customer/cart/updateProductSize",
          formData
        );
      } else {
        res = await this.http.API.$post(
          "guest/cart/updateProductSize",
          formData
        );
      }

      if (res.status === "success") {
        this.store.commit("shop/cart/setProductSize", {
          $auth: this.auth,
          idCartDetail,
          size,
        });
        return {
          status: "success",
          msg: "The size is updated",
        };
      }

      return {
        status: "error",
        msg: "The size is not updated",
      };
    } catch (error) {
      console.error(error);
      return {
        status: "error",
        msg: "The size is not updated",
      };
    }
  }

  getTotalCart() {
    return this.store.state.shop.cart.cartTotal;
  }

  getIdcart() {
    return this.store.state.shop.cart.idCart;
  }

  getLastItem() {
    const list = this.store.state.shop.cart.listItemLocal;
    if (list.length) {
      return list[0];
    }
    return null;
  }

  init() {
    this.store.commit("shop/cart/initialiseStore", this.auth);
  }
}
