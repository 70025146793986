import gql from "graphql-tag";

const BANNER_ITEM = gql `
  fragment BannrItem on LookBookContentDynamicZone {
    ... on ComponentGlobalBannerItem {
      id
      title
      subTitle
      link
      link_text
      image {
        data {
          attributes {
            url
          }
        }
      }
      type
      video_url
    }
  }
`;

const ITEMS_FULL = gql `
  fragment ItemsFull on LookBookContentDynamicZone {
    ... on ComponentLookBookLookBookItem {
      __typename
      id
      image {
        data {
          attributes {
            url
          }
        }
      }
      products {
        id
        product_url
        column
        row
      }
      video_url
    }
  }
`;

const ITEMS_COLS = gql `
  fragment ItemsCol on LookBookContentDynamicZone {
    ... on ComponentLookBookLookBookCols {
      __typename
      id
      image_left {
        data {
          attributes {
            url
          }
        }
      }
      products_left {
        id
        product_url
        column
        row
      }
      image_right {
        data {
          attributes {
            url
          }
        }
      }
      products_right {
        id
        product_url
        column
        row
      }
    }
  }
`;

export const LookBooksQuery = gql `
  ${BANNER_ITEM}
  ${ITEMS_COLS}
  ${ITEMS_FULL}
  query getLookBooksPageDetail($lang: I18NLocaleCode, $permalink: String) {
    lookBooks(
      locale: $lang
      filters: { seo: { permalink: { eq: $permalink } } }
    ) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
              }
            }
          }
          seo {
            permalink
            pageTitle
            discription
          }
          locale
          localizations {
            data {
              attributes {
                seo {
                  permalink
                }
                locale
              }
              
            }
          }
          content {
            ...BannrItem
            ...ItemsCol
            ...ItemsFull
          }
        }
      }
    }
  }
`;

export const LookBooksPageQuery = gql `
  query getLookBooksPage($lang: I18NLocaleCode){
    lookBooks(locale :$lang) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
              }
            }
          }
          seo {
            permalink
          }
        }
      }
    }
  }
`;

export const LookBooksMenuQuery = gql `
  query getLookBooksMenu($lang: I18NLocaleCode) {
    lookBooks(locale: $lang, filters: { showInMenu: { eq: true } }, sort: "id:desc") {
      data {
        id
        attributes {
          title
          thumbnailImage {
            data {
              attributes {
                url
              }
            }
          }
          positionInMenu
          seo {
            permalink
          }
        }
      }
    }
  }
`;
