import { AppService } from "./AppService";

export class CarriersService extends AppService {
  get data() {
    return this.store.state.carriers;
  }

  fetchCarrierTypes({ idAddress, onSuccess, onError } = {}) {
    this.store.dispatch("carriers/fetchCarrierTypes", {
      idAddress,
      onSuccess,
      onError,
    });
  }

  fetchCarriers({
    idAddress,
    carrierType,
    responseLevel,
    onSuccess,
    onError,
  } = {}) {
    this.store.dispatch("carriers/fetchCarriers", {
      idAddress,
      carrierType,
      responseLevel,
      onSuccess,
      onError,
    });
  }

  async getCarriers({
    idAddress,
    languageIsoCode,
    currencyIsoCode,
    carrierType,
    responseLevel,
  }) {
    let url = `carrier?IdAddress=${idAddress}&LanguageIsoCode=${languageIsoCode}`;

    if (carrierType) {
      url += `&CarrierType=${carrierType}`;
    }

    if (currencyIsoCode) {
      url += `&CurrencyIsoCode=${currencyIsoCode}`;
    }

    if (responseLevel) {
      url += `&ResponseLevel=${responseLevel}`;
    }

    const response = await this.http.API_AUTH.get(url);
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
}
