export const state = () => ({
  list: []
})


export const mutations = {
  initialiseStore(state, list) {
    state.list = list;
  }
}
