export const state = () => ({
  product: {},
  color: null,
  show: false,
});

export const mutations = {
  setProduct(state, { product, color }) {
    state.product = product;
    state.color = color;
    state.show = true;
  },
  removeProduct(state, value) {
    state.product = {};
    state.show = value;
  },
};
