import { ProductHelpers } from "@/helpers/ProductHelpers";

export default async (context) => {
  const local = context.app.i18n.locale;
  const productHelpers = new ProductHelpers(
    context.$http,
    context,
    local ? local : "fr"
  );
  productHelpers.getCategories().then((categories) => {
    if (Array.isArray(categories)) {

      const categoriesLinks = [];
      let mainCategory = null;

      categories.forEach((item) => {

        if (item.MainCategory === true) {
          mainCategory = item;
        } else {
          item.SubCategories.forEach((subCategory) => {
            if (subCategory.MainCategory === true) {
              mainCategory = subCategory;
            }
          });
        }

        if (mainCategory !== null) {
          context.store.commit("shop/categories/setMainCategory", mainCategory);
        }

        const link = item.CategoryDescriptionFriendlyURL
            ? item.CategoryDescriptionFriendlyURL
            : "#";

          const categoryData = {
            text: item.CategoryName,
            link: context.app.localePath(`/${link}`),
            item: item,
          };

          categoriesLinks.push(categoryData);

      });

      context.store.commit("shop/categories/init", categoriesLinks);
    }
  });
};
