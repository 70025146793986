import { LoyaltyService } from '@/helpers/LoyaltyService';

export const state = () => ({
  loading: true,
  error: false,
  data: null,
});

export const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  setData(state, data) {
    state.data = data;
  },
};

export const actions = {
  fetchData({ commit }) {
    commit('setLoading', true);

    const loyaltyService = new LoyaltyService(this.$http);
    loyaltyService
      .addGoogleWallet()
      .then((data) => {
        console.log(data);

        commit('setData', data);
      })
      .catch((err) => {
        this.error = err;
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
};
