import gql from "graphql-tag";

// Page shop

export const ShopPage = gql`
  query getPageShop($lang: I18NLocaleCode) {
    shopPage(locale: $lang) {
      data {
        attributes {
          title
          image_banner {
            data {
              attributes {
                url
              }
            }
          }
          content
          image_body {
            data {
              attributes {
                url
              }
            }
          }
          address_shops {
            data {
              id
              attributes {
                title
                address
                zip_code
                discription
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                seo {
                  id
                  permalink
                  pageTitle
                  discription
                }
              }
            }
          }
          list_of_shops {
            id
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ShopPageDetail = gql`
  query getAddress($permalink: String, $lang: I18NLocaleCode) {
    addressShops(
      locale: $lang
      filters: { seo: { permalink: { eq: $permalink } } }
    ) {
      data {
        id
        attributes {
          title
          address
          zip_code
          discription
          image {
            data {
              attributes {
                url
              }
            }
          }
          schedules {
            day
            start
            end
          }
          seo {
            id
            permalink
            pageTitle
            discription
          }
          locale
          localizations {
            data {
              attributes {
                seo {
                  permalink
                }
                locale
              }
            }
          }
        }
      }
    }
  }
`;

// getAddress

export const SectionListAddress = gql`
  query getAddress {
    sectionListAddress {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
              }
            }
          }
          address_shops {
            data {
              attributes {
                title
                seo {
                  permalink
                }
              }
            }
          }
        }
      }
    }
  }
`;

// topBanner

export const TopBanner = gql`
  query topBanner($lang: I18NLocaleCode) {
    topBanner(locale: $lang) {
      data {
        attributes {
          body
          link
          active
        }
      }
    }
  }
`;
