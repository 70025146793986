const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['Localisation'] = require('../middleware/Localisation.js')
middleware['Localisation'] = middleware['Localisation'].default || middleware['Localisation']

middleware['mdAddress'] = require('../middleware/mdAddress.js')
middleware['mdAddress'] = middleware['mdAddress'].default || middleware['mdAddress']

middleware['resetpassword'] = require('../middleware/resetpassword.js')
middleware['resetpassword'] = middleware['resetpassword'].default || middleware['resetpassword']

middleware['Tunnel'] = require('../middleware/Tunnel.js')
middleware['Tunnel'] = middleware['Tunnel'].default || middleware['Tunnel']

export default middleware
