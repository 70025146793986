const FR = {
  'lang-en': 'Anglais',
  'lang-fr': 'Français',
  'lang-de': 'Allemand',

  from: 'dès',

  'My account': 'Mon compte',
  Products: 'Produits',
  'for women from to': 'pour femme du {0} au {1}',

  '{0} products found for « {1} »': '{0} produits trouvés pour « {1} »',
  Reset: 'Réinitialiser',
  Filter: 'Filtrer',
  'View all {0} products': 'Voir les {0} produits',
  'Sort by ascending price': 'Trier par prix croissant',
  'Sort by descending price': 'Trier par prix décroissant',

  found: 'trouvées',
  'Last products viewed': 'Dernier produits vus',
  'See my favorites': 'Voir mes favoris',
  'See more {0}': 'Voir plus de {0}',
  'Add to Cart': 'Ajouter au panier',
  'Add to favorite': 'Ajouter aux favoris',
  'Not available': 'Non disponible',
  "We're sorry, this item is no longer available.":
    "Nous sommes désolés,<br>cet article n'est plus disponible",
  'Not available, notify me when back in stock':
    'Non disponible, m’avertir du retour en stock',
  'Notify me when back in stock': 'M’avertir du retour en stock',
  Send: 'Envoyer',
  'You will like also': 'Vous aimerez aussi',
  'Continue my shopping': 'Continuer mon shopping',
  'The JMP news': 'La news JMP',
  'Do you wish to order by phone': 'Vous souhaitez commander par téléphone',
  'Product association': 'Association produit',
  'Similar products': 'Produits similaires',
  'your shopping cart': 'votre panier',
  articles: 'articles',
  Total: 'Total',
  Subtotal: 'Sous-total',
  Delivery: 'Livraison',
  'Your delivery': 'Votre livraison',
  'Delivery address': 'Adress de livraison',
  'Total (VAT INCLUDED)': 'Total (TVA INCLUSE)',
  'Please enter or add the discount code here':
    'Veuillez entrer ou ajouter le code de réduction ici',
  Command: 'Commander',
  'Your Coupon Code': 'Votre code promo',
  Save: 'Enregistrer',
  'Complete With': 'Compléter avec',
  'My favorites': 'My favorites',
  'Saved for later': 'Sauvegardé pour plus tard',
  'Validate my order': 'Valider ma commande',
  'ADD AN ADDRESS': 'Ajouter une adresse',
  'Edit an address': 'Modifier une adresse',
  'First name': 'Prénom',
  'Last name': 'Nom',
  Address: 'Adresse',
  'Additional address': 'Complement d’adresse',
  'ZIP code': 'Code postal',
  Country: 'Pays',
  City: 'Ville',
  'Mobile phone': 'Téléphone mobile',
  Phone: 'Téléphone',
  'Address name': 'Nom de l’adresse',
  'Set as default delivery address':
    'Définir comme adresse de livraison par défaut',
  'Set as default billing address':
    'Définir comme adresse de facturation par défaut',
  'Required fields': 'Champs obligatoires',
  'At least {0} characters': 'Au moins {0} caractères',
  'Length should be 6 to 15': 'La longueur doit être de 6 à 15 caractères',
  "By registering for an account, you agree to our terms of use. Please read our <a href='{0}' class='underline'>privacy policy.</a>":
    "En vous enregistrant pour créer un compte, vous acceptez nos modalités d’utlisation. Veuillez lire notre <a href='{0}' class='underline' target='_blank'>politique de confidentialité.</a>",
  'My Address': 'Mon adresse',
  'I am new here': 'Je suis nouveau ici',
  'Good morning': 'Je suis nouveau ici',
  Login: 'Se connecter',
  "Login with Email/Password don't exists":
    "La connexion avec e-mail/mot de passe n'existe pas",
  'Good morning': 'Bonjour',
  'Email address': 'Adresse email',
  Password: 'Mot de passe',
  'New password': 'Nouveau mot de passe',
  'Confirm password': 'Confirmez ce mot de passe',
  'Forgot your password': 'Mot de passe oublié',
  'Register with an email address': 'S’inscrire avec une adresse e-mail',
  'Or register with my social networks':
    'Ou s’inscrire sur mes réseaux sociaux',
  'Or connect with my social networks':
    'Ou me connecter avec mes réseaux sociaux',
  'Date of birth': 'Date de naissance',
  'E-mail': 'E-mail',
  'E-mail address': 'Adresse email',
  optional: 'facultatif',
  'Privacy Policy': 'Politique de confidentialité',
  'Terms of use': 'Conditions d’utilisation',
  'Legal Notice': 'Mentions Légales',
  'Please select a size': 'Veuillez choisir une taille',
  'Validate my cart': 'Valider mon panier',
  'Reserve this product in store': 'Réserver ce produit en boutique',

  'Delivered on': 'Livré le',
  'See order': 'Voir la commande',
  Invoice: 'Facture',
  'Return an item': 'Retourner un article',
  Invoicing: 'Facturation',
  Sending: 'Envoi',
  Information: 'Informations',
  'Order Date': 'Date de commande',
  'Order number': 'Numéro de commande',
  'Sending mode': 'Mode d’envoi',
  'Payment method': 'Mode de paiement',
  'Track my delivery': 'Suivre ma livraison',
  'You have a question': 'Vous avez une question',
  'Order Detail': 'Détail de la commande',
  Quantities: 'Quantités',
  'Download my invoice': 'Télécharger ma facture',
  'Return requested': 'Retour demandé',
  'Return type': 'Type de retour',
  'Return status': 'Statut du retour',
  'Order Summary': 'Récapitulatif de la commande',
  'Total products': 'Total produits',
  'Only {0} left to take advantage of free home delivery':
    'Plus que {0} pour profiter de la livraison offerte à domicile',
  'Estimated delivery': 'Livraison estimée',
  '<u>Free delivery from {0}</u> at home and at a pick-up point':
    '<u>Livraison offerte dès {0}</u> à domicile et en point relais',
  'We accept payment methods': 'Nous acceptons les moyens de paiements',
  'Your delivery adress': 'Votre adresse de livraison',
  'Create a new delivery address': 'Créer une nouvelle adresse de livraison',
  'Edit this address': 'Modifier cette adresse',
  'Use another address for billing':
    'Utiliser une autres adresse pour la facturation',
  'Your billing address': 'Votre adresse de facturation',
  'Your delivery option': 'Votre option de livraison',
  'Continue to payment': 'Continuer vers le paiement',
  carrierType_Home: 'À domicile',
  carrierType_RelayPoint: 'En point relais',
  carrierType_Store: 'En boutiques',
  Edit: 'Modifier',
  'See all products': 'Voir tous les produits',

  'Payment method for {0}': 'Méthodes de paiement pour {0}',
  'Payment methods': 'Méthodes de paiement',
  '100% secure. Your bank may ask you to authorize payment to complete your purchase.':
    '100% sécurisé. Votre banque peut vous demander d’autoriser le paiement pour compléter votre achat.',
  'I accept the general sales conditions':
    "En poursuivant ma commande je reconnais avoir lu et accepté <a class='underline' href='{terms_link}' target='_blank'>les conditions générales de vente</a>, ainsi que mon souhait de procéder au paiement de la commande avant toute livraison.",
  'Credit card': 'Cartes de crédit',
  'Secure payment with {0}': 'Paiement sécurisé avec {0}',
  'Pay with': 'Paiement sécurisé avec',
  'You will be redirected to the secure payment site.':
    'Vous serez redirigé vers le site de paiement sécurisé.',

  News: 'Nouveautés',
  'Plus Size Fishion': 'Mode grande taille',
  'The brand': 'Univers',
  'Our shops': 'Boutiques',
  Magazine: 'Journal',
  Home: 'Accueil',
  Collection: 'Collection',
  Clothes: 'Vêtements',
  'In the heart of the rock': 'Au coeur de la roche',
  'The origin of the waves': 'L’origine des vagues',
  'The power of roses': 'Le pouvoir des roses',
  'Back to basic': 'Retour à la base',
  'The power of roses': 'Le pouvoire des roses',
  Lookbooks: 'Lookbooks',
  FAQ: 'FAQ',
  Discover: 'Découvrir',
  'See more articles': 'Voir plus d’articles',
  'All items': 'Tous les articles',
  'See the lookbook': 'Voir le lookbook',

  'PAYMENT IN SEVERAL TIMES': 'PAIEMENT EN PLUSIEURS FOIS',
  'Payment 100% secured': 'Paiement 100% sécurisé',
  'Pay in installments from 150': 'Payer en plusieurs fois dès 150',
  'Our secure payment methods': 'Nos méthodes de paiement sécurisés',
  'Bank card, Klarna 3X free of charge from €150, <br />Paypal, American Express':
    'Carte Bancaire, Klarna 3X sans frais dès 150€, <br />Paypal, American Express',
  'Delivery and returns': 'Livraison et retours',
  'Delivery in 2-3 days': 'Livraison en 2-3 jours',
  'Easy returns and exchanges': 'Retour et échanges facilités',
  'Free 2-3 day delivery & Return': 'Livraison 2-3 jours & Retour offerts',
  'Refund & size exchange <br />Full details here.':
    'Remboursement & échange de taille <br />Tous les détails ici.',
  'Customer Service': 'Service client',
  'Monday, Tuesday, Thursday, Friday (10.30am-1pm / 2pm-5.30pm)':
    'Lundi, Mardi, Jeudi, Vendredi (10h30-13h / 14h-17h30)',
  'Receive our news in preview and take advantage of our exclusive offers!':
    'Recevez notre actualité en avant-première et profitez de nos offres exclusives !',
  Register: 'S’inscrire',
  'Let’s stay connected': 'Restons connectés',

  'Frequently Asked Questions': 'Foire aux questions',
  'Delivery & Returns': 'Livraisons & Retours',
  'Track my order': 'Suivi de ma commande',
  'Follow-up of my order outside France': 'Suivi de ma commande hors France',
  CGV: 'CGV',
  Privacy: 'Confidentialité',
  'Cookie settings': 'Paramètres des cookies',
  'Join us': 'Nous rejoindre',
  Manifesto: 'Manifesto',
  'Our commitments': 'Nos engagements',
  'The Diary': 'Le journal',
  Lookbooks: 'Lookbooks',
  '89 Rivoli street, 75001 Paris': '89 Rue de Rivoli, 75001 Paris',
  '95 Saint-Lazare street, 75009 Paris': '95 rue Saint-Lazare, 75009 Paris',
  '92 Alesia street, 75 014 Paris': '92 rue d’Alésia, 75 014 Paris',
  '57 Poland Street, 78100 Saint Germain-en-Laye':
    '57 rue de Pologne, 78 100 Saint Germain-en-Laye',
  '2 Jean Roisin street, 59 800 Lille': '2 rue Jean Roisin, 59 800 Lille',
  CGV: 'CGV',
  HELP: 'AIDE',
  ABOUT: 'À PROPOS',
  'Need help': "Besoin d'aide",
  page_faq_disc:
    'Une question sur votre commande, sa livraison, les modes de paiements possibles ou encore les retours, les échanges et remboursements, la réponse à vos questions est surement dans notre FAQ et sino vous pouvez nous contacter',

  'My profile': 'Mon profil',
  'My information': 'Mes informations',
  'My addresses': 'Mes adresses',
  Disconnect: 'Déconnecter',
  'My orders': 'Mes commandes',
  'my shopping carts': 'Mes paniers',
  'My returns': 'Mes retours',
  'My favorites': 'Mes favoris',
  HELP: 'AIDE',

  'Default Delivery': 'Livraison par défaut',
  'Default Billing': 'Facturation par défaut',
  'Edit Address': 'Modifier l’adresse',
  'Delete this address': 'Supprimer cette adresse',

  'My loyalty program': 'Le programme',
  'My Membership': 'Rejoindre le Club',
  'My advantages': 'Gagner des points',
  'My history': 'Historique',
  'Join for free': 'Adhérer gratuitement ',
  'Use my points': 'Utiliser mes points',
  'Get the reward': 'Obtenir la récompense',
  'MY LOYALTY CARD': 'MA CARTE FIDÉLITÉ',
  'LOYALTY CARD': 'CARTE FIDÉLITÉ',
  Yes: 'Oui',
  No: 'Non',
  loyalty_page_title:
    '<b>CLUB JMP</b> <br /> Le programme fidélité qui vous avantage',
  loyalty_page_disc:
    '<b>CLUB JMP</b> est le programme de fidélité de JMP où chaque client compte. Plus vous en profitez, Plus vos avantages évoluent. C’est gratuit et sans engagement !',

  'New account': 'Nouveau compte',
  'You can create an account in the following steps':
    'Vous pouvez créer un compte aux prochaines étapes',
  'Continue with guest Checkout': "Continue en tant qu'invité",

  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',

  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',

  'Please type your address': 'Veuillez saisir votre adresse',
  'Find an address': 'Recherche une adresse',
  Search: 'Recherche',

  Fullname: 'Nom complet',
  'Card number': 'Numéro de carte',
  'Expiry date': 'Date d’expiration',
  CVC: 'CVC',

  Checkout: 'Commander',
  'The three-digit code is on the back of the card':
    'Le code à trois chiffres est au dos de la carte',
  'This order has already been paid': 'Cette commande a déjà été payée',

  Preference: 'Préférences',
  'Make your purchases in': 'Faites vos achats en',
  'Your language': 'Votre langage',
  'Your currency': 'Votre devise',
  Currency: 'Devise',
  'Contact language': 'Langue de contact',
  'Update preferences': 'Mettre à jour les préférences',

  'Products in my cart': 'Produits dans mon panier',
  account: 'Compte',
  Favorites: 'Favoris',
  Cart: 'Panier',
  Connection: 'Connexion',
  'See all my favorites': 'Voir tous mes favoris',
  'Free delivery from €150 at home and at a pick-up point. Payment in 3x free of charge from €150':
    '<u>Livraison offerte dès 79€</u> à domicile et en point relais. Paiement en 3x sans frais dès 150€',

  Quantity: 'Quantité',
  Size: 'Taille',
  'See more': 'Voir plus',
  'Filter by': 'Filtrer par',
  Sizes: 'Tailles',
  Colors: 'Couleurs',
  'Price from {0} to {1}': 'Prix de {0} à {1}',

  '{0} points available': '{0} Points disponibles',
  'My loyalty points': 'Mes points fidélité',
  'Total : {0}pts': 'Total : {0} pts',
  'My point history': 'Mon historique de points',
  'Welcome to your loyalty area.': 'Bienvenue au Club JMP',
  'Earn points and enjoy your benefits.':
    'Obtenez des points et profitez de vos avantages.',
  'Show my loyalty card': 'Afficher ma carte fidélité',
  'Redeem my points': 'Utiliser mes points',
  'Get points': 'Gagner des points avec les évènements',
  modal_event_body:
    'Une fois vos nouveaux points obtenus, tenez compte de la date de validité de vos points afin de les utiliser. Nous vous avertirons avant l’échéance de vos points par email en cas d’oubli.',
  'points available': 'Points disponibles',
  'Buy in store and online': 'Achetez en boutique et en ligne',
  'Earn points by purchasing JMP items and redeem them for discounts.':
    'Obtenez des points en achetant des articles JMP et échangez-les contre des réductions.',
  'Buy now': 'Acheter maintenant',
  'Birthday present': "Cadeau d'anniversaire",
  Birthday: 'Anniversaire',
  'Receive a special gift for your birthday.':
    'Recevez un cadeau spécial <br /> pour votre anniversaire.',
  points: 'points',
  'Identify yourself in store': 'Identifiez-vous en boutique',
  'Present your JMP loyalty card in store. You will find the code in « My Account »':
    'Présentez votre carte fidélité JMP en boutique. <br/> Vous trouverez le code dans « Mon compte ».',
  'My membership in the {0} loyalty program':
    'Mon adhésion au programme fidélité {0}',
  'Would you like to join the {0} loyalty program?':
    'Mon adhésion au programme fidélité {0} ?',
  loyalty_form_text:
    '<p> Adhérez gratuitement au programme de fidélité JMP pour cumuler des points à chaque commande et bénéficier de bons de réduction, recevoir des offres exclusives et évoluer au sein de 3 statuts de plus en plus généreux. Profitez également de tous les privilèges JMP dans vos magasins JMP. </p> <br /> <p> Le programme de fidélité JMP est réservé à toute personne physique âgée de plus de 18 ans </p>',
  'Find out more about the loyalty program':
    'En savoir plus sur le programme fidélité',
  'Happy birthday': 'Joyeux anniversaire',
  'Your points have expired': 'Vos points ont expirés',
  'Order No': 'Commande N°',
  '{0} points received': '{0} points reçus',
  '{0} points used': '{0} points utilisés',
  'Loyalty program membership': 'Adhésion au programme fidélité',
  'Join the loyalty program': 'Rejoindre le Club JMP',

  'Type something': 'Tapez quelque chose',

  'Or 3x {0} with the <u>payment in 3x free</u>':
    'Ou 3x {0} avec le <u>paiement en 3x sans frais</u>',
  Color: 'Couleur',
  'Availability in store': 'Disponibilité en boutique',
  'Free delivery': 'Livraison offerte',
  days: 'jours',
  'Free return': 'Retour gratuit',
  'Secure payment': 'Paiement sécurisé',
  'free of charge': 'sans frais',
  Measurements: 'Mensurations',
  'How do I take my measurements?': 'Comment prendre mes mesures ?',
  'Please refer to the charts below to determine your size':
    ' Merci de vous référer aux grilles ci-dessous pour déterminer votre taille',
  measurements_disc:
    ' Prenez vos mensurations à l’aide d’un mètre ruban, puis reportez-vous au tableau ci-dessous pour connaitre votre taille. Contactez notre service client si vous avez besoin de connaître l’ajustement d’un article en particulier au {0}.',
  'Chest size': 'Tour de poitrine',
  'Place the tape measure at armpit level, where it is strongest, and go around.':
    'Placez le mètre ruban au niveau de vos aisselles, à l’endroit le plus fort, et faites le tour.',
  'Waist size': 'Tour de taille',
  'Place the tape measure at the hollow of your waist, below your ribs and above your hips.':
    'Placez le mètre ruban au niveau du creux de votre taille, en dessous de vos côtes et au-dessus de vos hanches.',
  'Hip circumference': 'Tour de hanches',
  'Place the tape measure at the fullest part of your hips.':
    'Placez le mètre ruban à l’endroit le plus fort de vos hanches.',
  'Size guide': 'Guide des tailles',
  Description: 'Description',
  'Material and fit': 'Matière et coupe',
  Chest: 'Poitrine',
  Hips: 'Hanches',
  times: 'fois',
  sofort_disc:
    'SOFORT was founded in 2005 in Munich, Germany, and is the #1 Bank Transfer provider in Europe, with big plans to grow in the United Kingdom. With wide market coverage and strong brand awareness, we’re also one of the top 3 most-used payment methods overall in Germany and Austria.',
  bancontact_disc:
    'Payer très facilement de petits montants avec Bancontact : facile et rapide. Payer n’importe quel montant, même le plus petit, c’est très facile avec la carte Bancontact.',
  ideal_disc:
    'Vous souhaitez que vos clients paient plus souvent avec iDEAL ? Avec quelques modifications simples à votre page de paiement, vous pouvez encourager vos clients à payer rapidement, en toute sécurité et en toute sécurité avec iDEAL.',
  giropay_disc:
    'Payez en ligne avec Giropay - en toute sécurité et directement depuis votre compte courant.',
  'Payment in installments with Alma': 'Paiement en plusieurs fois avec Alma',
  alma_disc: 'Paiement en 2,3 ou 4 fois sans frais.',
  Payment: 'Paiement',
  Continue: 'Continuer',
  'Continue mon shopping': 'Continuer mon shopping',
  'Continue Shopping': 'Continuer les achats',
  'Free delivery & easy return': 'Livraison offerte & retour facile',
  Next: 'Suivant',
  'To see the availability of this product in stores, please select the desired size.':
    'Afin de voir la disponibilité en boutique de ce produit, veuillez sélectionner la taille souhaitée.',
  Returns: 'Retours',
  Loyalty: 'Fidélité',
  back_in_stock_nb:
    "En inscrivant votre email, vous acceptez que Jean Marc Philippe utilise votre adresse email pour vous tenir informé de la disponibilité de ce produit et vous suggérer des alternatives si le produit n'est pas de retour en stock dans les 15 jours. Cette alerte de stock sera effacée après 15 jours. Vous pouvez bénéficier d'un droit d'accès, de modification, de suppression de traitement de vos données sur simple demande au service de protection des données (data@jeanmarcphilippe.com). Pour plus d'informations, veuillez consulter notre politique de confidentialité.",
  back_in_stock_validate:
    "<p> Nous avons bien enregistré votre demande. Une confirmation vous a été envoyée par email à {mail} </p> <p>Produit de retour en stock ?</p> <ul class='ul-list'> <li> Nous vous enverrons un email pour vous informer du retour en stock de ce produit. </li> </ul>",
  copyright:
    "© {0}, {1} réalisé avec <a href='{2}' class='underline'>Digipart Commerce Cloud</a>",

  order_thanks:
    "<h2> Merci {name}, <br /> Pour votre commande </h2> <p> Nous avons bien enregistré votre achat et vous remercions de votre confiance. </p> <p> Nous vous invitons à vérifier le détail de votre commande et votre adresse de livraison. <br /> Si vous constatez une erreur, merci de nous contacter par email à <a href='mailto:{mail}' >{mail}</a > ou par téléphone au <a href='tel:{tel}'>{tel_text}</a>. </p> <p> <i> Nous vous informerons des prochaines étapes de préparation et d’expédition de votre commande par email. Vous pouvez aussi suivre l’état de votre commande dans votre compte sur jeanmarcphilippe.com dans l’onglet <a href='{order_link}'>mes commandes </a>. </i> </p>",
  'A question about your order?': 'Une question sur votre commande ?',
  'See the details of my order': 'Voir le détail de ma commande',
  Order: 'Commande',
  order_thanks_decline: `<h2>COMMANDE NON FINALISÉE !</h2>
      <p>Votre commande a été abandonnée pendant le processus de paiement.</p>
      <p>Nous vous invitons à retourner sur <a href='{cart_link}' >votre panier</a > pour le vérifier, choisir votre option de livraison et votre méthode de paiement pour finaliser votre commande. </p>
      <p>Si vous constatez une erreur, merci de nous contacter par email à <a href='mailto:{mail}' >{mail}</a > ou par téléphone au <a href='tel:{tel}'>{tel_text}</a>.</p>`,
  'See the details of my cart': 'Voir le détail de mon panier',
  newsletter_title: '20€ OFFERTS POUR LA 1ÈRE COMMANDE',
  newsletter_text:
    'Inscrivez-vous à notre newsletter et recevez <b>20€ de réductions</b> pour votre première commande sur la nouvelle collection.*',
  newsletter_modal_nb:
    '*En inscrivant votre email, vous acceptez que Jean Marc Philippe utilise votre adresse email pour vous envoyer des messages par email.',

  'thank you for subscribing': 'merci pour <br /> votre inscription !',
  newsletter_confirmation:
    'Une confirmation vous a été envoyée par email à {mail}. Vous recevrez prochainement la newsletter JMP.',

  'Create your JMP space for a personalized shopping experience':
    "Créer votre espace JMP pour une expérience d'achat personnalisé",
  'No product added to cart': 'Aucun produit ajouté au panier',
  'No favorites added': 'Aucun favori ajouté',

  Product: 'Produit',
  Shop: 'Boutique',
  'Contact details': 'Coordonnées',
  Confirmation: 'Confirmation',
  Article: 'Article',
  'Your contact details': 'Vos coordonnées',
  Civility: 'Civilité',
  Madam: 'Madame',
  Mr: 'Monsieur',
  'I authorize {0} to inform me of good plans and news':
    "J'autorise {0} à m'informer des bons plans et nouveautés",
  Confirm: 'Valider',
  'See the store': 'Voir la boutique',
  'thank you, for your reservation':
    'Merci {name}, <br /> pour votre réservation',
  reservation_confirmes_disc:
    '<p>La réservation est gratuite et sans obligation d’achat.</p> <br /> <p> <i> La boutique vous confirmera prochainement que le produit a bien été mis de côté par email ou téléphone. <u>Attendez de recevoir cette confirmation</u> avant de vous déplacer en boutique ! </i> </p>',

  'Last product in stock': 'Dernier produit en stock',
  'Out of stock': 'En rupture de stock',
  'See on the map': 'Voir sur la carte',
  'Schedules and info': 'Horaires et infos',
  'Choose This Shop': 'Choisir Cette boutique',

  Previous: 'Précédent',
  'Out Of Stock': 'Rupture de stock',
  'Limited Stock': 'Stock limité',
  'In Stock': 'En stock',
  'Please select a shop': 'Veuillez sélectionner une boutique',

  'Create a return': 'Créer un retour',
  create_return_confirmed:
    "<h2>Demande de retour confirmé</h2> <p>Nous avons bien enregistré votre demande de retour.</p> <p> Vous trouverez ci-dessous votre bon de retour et votre étiquette de transport pour retourner votre commande N° 37896. Veuillez compléter le bon de retour à joindre dans votre colis et utiliser l’étiquette de transport à coller sur votre colis. Pour toute question sur les retours, suivez la notice explicative de retour. </p> <p> <i> Nous vous informerons des la réception de votre colis afin de procéder à l’échange de taille ou au remboursement demandé. Vous pouvez aussi suivre l’état de votre retour dans votre compte sur jeanmarcphilippe.com dans l’onglet <a href='{myReturnsLink}'>mes retours </a>.. </i> </p>",
  'Documents of your return': 'Documents de votre retour',
  'Return voucher': 'Bon de retour',
  'Transport label': 'Étiquette de transport',
  'Explanatory note on returns': 'Notice explicative sur les retours',
  'Return detail': 'Détail du retour',
  Details: 'Détails',
  'View original order': 'Voir la commande initiale',
  'View Return': 'Voir le retour',
  'Return Tracking': 'Suivi du retour',
  'Return request validated': 'Demande de retour validé',
  'Return documents available': 'Documents de retour disponibles',
  'Return parcel sent': 'Colis de retour expédié',
  'Your points have expired': 'Vos points ont expirés',
  'Return received, processing in progress': 'Retour reçu, traitement en cours',
  'Return complete': 'Retour terminé',
  Exchange: 'Échange',
  Refund: 'Remboursement',
  'Select a size to continue': 'Sélectionnez une taille pour continuer',

  'My returns': 'Mes retours',
  'Use at least 6 characters': 'Utiliser au moins 6 caractères',
  'By registering for an account, you agree to our terms of use. Please read our privacy policy.':
    'En vous enregistrant pour créer un compte, vous acceptez nos modalités d’utlisation. Veuillez lire notre politique de confidentialité.',
  'All the questions': 'Toutes les questions',
  FEATURED: 'À LA UNE',
  'Reset instructions have been sent to':
    'Les instructions de réinitialisation ont été envoyées à',
  'You did not receive the email': "Vous n'avez pas reçu l'email",
  'Check your spam folder': 'Vérifiez votre dossier Spam',
  'Check the given email to reset the password':
    "Vérifier l'email indiqué pour réinitialiser le mot de passe",
  'Wait a few minutes before trying again, as some requests are slow to process':
    'Attendez quelques minutes avant de réessayer, car certaines demandes sont lentes à traiter',
  'See our addresses': 'Voir nos adresses',
  Schedule: 'Horaires',
  'Contact us': 'Nous contacter',
  Itinerary: 'Itinéraire',
  'Open in map': 'Ouvrir dans map',
  'Schedules and info': 'Horaires et infos',
  'other relay points nearby': 'autre points relais à proximité',
  'Choose another relay point': 'Choisir un autre point relais',
  'Your address': 'Votre adresse',
  Map: 'Carte',
  'Choose this parcel relay': 'Choisir ce relais colis',
  'Promo Code / Voucher': "Code Promo / Bon d'achat",
  form_abandoned_nb:
    'En inscrivant votre email, vous acceptez que Jean Marc Philippe utilise votre adresse email pour vous envoyer des messages concernant votre panier pendant la finalisation de l’achat.',
  'Do you hesitate': 'Vous hésitez',
  'We have registered your request. A confirmation has been sent to you by email at {mail}':
    "Nous avons enregistré votre demande. Une confirmation vous a été envoyée par e-mail à <a href='{order_link}'>{mail}</a>",
  'Your promo codes': 'Votre codes de promo',
  'the product you requested no longer exists.':
    "la produits que vous avez demandée n'existe plus !",
  'This category does not exist': "Cette category n'existe pas !",
  "This shop doesn't exist": "Cette boutique n'existe pas !",
  'This page does not exist !': "Cette page n'existe pas !",
  Newsletter: 'Newsletter',
  'I would like to receive the {0} newsletter':
    'Je souhaite recevoir la newsletter {0}',
  'My newsletter subscription': 'Mon abonnement à la newsletter',
  newsletter_nb:
    'Notre équipe de rédaction vous envoie du contenu personnalisé pour vous informer de nos dernières actualités, des nouvelles tendances, des sorties de collections, de nos meilleures offres, promotions et soldes. Vous pouvez modifier vos préférences ici, à tout moment.',
  account_news_letter_p1:
    "J'ai lu et compris <a href='{0}' class='underline'>la Politique de confidentialité et en matière de cookies</a>, et j'accepte de recevoir des communications commerciales personnalisées de la part de {1} via e-mail.",
  account_news_letter_p2:
    "En cas de modification de vos préférences, les changements peuvent prendre jusqu'à 7 jours avant d'être effectifs.",
  'You have successfully registered': 'vous avez réussi à vous inscrire',
  'You have successfully unsubscribed': 'Vous avez réussi à vous désinscrire',

  'Customer service': 'Service client',
  Assistance: 'Assistance',
  'My requests': 'Mes demandes',
  'Do you need help with a recent article, {0} ?':
    "Avez-vous besoin d'aide concernant un article récent, {0} ?",
  'Select the item below that you need help with or get help with something else':
    "Sélectionnez ci-dessous l'article pour lequel vous avez besoin d'aide ou <a href='{faq_url}' class='underline'>obtenez de l'aide pour autre chose</a>",
  'Required Field': 'Champ obligatoire',
  'Your message': 'Votre message',
  'Type your message': 'Tapez votre message',
  'Send your request': 'Envoyer votre demande',
  'Send your message': 'Envoyer votre message',
  'Last message': 'Dernier message',
  Message: 'Message',
  ticket_state__Closed: 'Demande traité',
  ticket_state__InProcess: 'Demande en cours',
  ticket_state__Opened: 'Demande en ouverte',
  'Add message': 'Ajouter un message',
  'View chat history': "Voir l'historique de la conversation",
  'Details of the ASSISTANCE request': "Détail de la demande d'ASSISTANCE",
  'Your evaluation has been successfully updated':
    'Votre évaluation a été mise à jour avec succès',
  'You can only modify your evaluation within a duration of 24 hours after the evaluation.':
    "Vous ne pouvez modifier votre évaluation que dans un délai de 24 heures après l'évaluation.",
  Success: 'Succès',
  Error: 'Erreur',
  'See history': "Voir l'historique",
  'ASSISTANCE in progress': 'ASSISTANCE en cours',
  estimated_delivery:
    '<b>Livraison estimée le {date}</b> si vous commandez avant 12h. <br />Livraison en <b>{country}</b>',
  estimated_delivery2:
    '<b>Livraison estimée entre le {dateMin} et le {dateMax}</b>. <br />Livraison en <b>{country}</b>',

  Accessories: 'Accessoires',
  'Last chance': 'Dernière chance',
  'Legal notice': 'Mention légale',

  home_title: 'JMP · Paris  · Jean Marc Philippe - JMP - Jean Marc Philippe',
  home_meta_discription:
    'La mode pour les femmes en 42 & plus · E-Boutique Officielle JMP · Paiement sécurisé · Satisfait ou remboursé · SAV à votre écoute.',

  order_state_created: 'Créé',
  order_state_preparation: 'En cours de préparation',
  order_state_shipped: 'Expédiée',
  order_state_delivered: 'livré',
  order_state_canceled: 'Annulée',
  Le: 'LE',
  shops_url: 'boutiques',
  shop_url: 'boutique',
  faq_livraisons_url: '/content/faq/livraisons',

  'Page not found': 'Page non trouvée',

  'This product is no longer available in the chosen version or quantity, modify or delete this product':
    "Ce produit n'est plus disponible dans la déclinaison ou la quantité choisie, modifier ou supprimer ce produit",
  'This product already exists with this size, modify or delete to continue':
    'Ce produit existe déjà avec cette taille, modifiez ou supprimer pour continuer',

  'Length should more than {0}': 'La longueur doit être supérieure à {0}',
  'Please input correct email address':
    "Veuillez saisir l'adresse e-mail correcte",
  'Enter your email address below to receive a reminder of this basket and your future cart.':
    'Entrez votre adresse e-mail ci-dessous pour recevoir un rappel de ce panier et de votre futur panier.',
  'I agree': "J'accepte",
  'Enter your email address below to receive a reminder of this basket and your future cart.':
    'Geben Sie unten Ihre E-Mail-Adresse ein, um eine Erinnerung an diesen Warenkorb und Ihren zukünftigen Warenkorb zu erhalten.',
  coockis_bar_text: `Jean Marc Philippe utilise des cookies techniques pour assurer le bon fonctionnement du site, personnaliser le contenu et vous offrir une expérience sur mesure. En cliquant sur "Accepter les cookies", vous autorisez le stockage de tous les cookies sur votre appareil. En fermant cette bannière, vous poursuivrez votre navigation en utilisant uniquement les cookies techniques. Pour en savoir plus sur les cookies, vous pouvez consulter notre politique en matière de cookies dans <a class='underline' href='{0}' target='_blank'>nos conditions générales</a>.`,
  'Sorted by': 'Trier par',
  'By default': 'Par défaut',
  'Ascending price': 'Prix croissant',
  'Decreasing price': 'Prix décroissant',
  Newest: 'Le plus récent',
  Oldest: 'Le plus ancien',
  'Choose my delivery': 'Choisir ma livraison',

  'Summer sales': "Soldes d'été",
  Sales: 'Soldes',
  'Summer Sales': "Soldes d'été",
  'Privacy Policy': 'Politique de confidentialité',

  'Please enter an address': 'Veuillez saisir une adresse',
  'Address line 2 (optional)': 'Adresse ligne 2 (facultative)',
  'Apartment, building, floor, door code, etc.':
    'Appartement, bâtiment, étage, code porte, etc.',
  'Choose the reason for the return': 'Choisir le motif du retour',
  register_newsletter:
    'Oui, je veux recevoir par e-mail des infos sur les dernières actualités et profiter d’offres et de remises exclusives. Désinscription possible à tout moment.',
  register_loyalty:
    "Oui, je souhaite adhérer gratuitement au  <a href='{0}' class='underline' target='_blank'> programme de fidélité JMP - Privilèges </a> pour cumuler des points à chaque commande et bénéficier de bons de réduction (en cadeau de bienvenue 250 points soit 10€ de réduction).",
  'A question about returns?': 'Une question sur les retours ?',

  'Email does not exist': "Email n'existe pas",
  'There is something not right': 'Il y a quelque chose qui ne va pas',

  'Reset your password': 'Réinitialisez votre mot de passe',
  'Back to login': 'Retour à la connexion',
  Previous: 'Précédente',
  autologin_text:
    'Veuillez patienter, vous serez redirigé vers la page de profil...',
  'The password was changed successfully':
    'Le mot de passe a été modifié avec succès !',
  unsubscribe: 'Se désabonner',
  'Add card to the google wallet': 'Ajouter ma carte au Google Wallet',
  'Use my points': 'Utiliser mes points',
  'The statuses': 'Les statuts',
  'Turn your points into rewards': 'Transformez vos points en récompenses',
  'Take advantage of your loyalty program...':
    'Profitez des évènements de votre programme fidélité pour gagner des points et passer au niveaux supérieurs !',

  //Routing -----------------------
  account_profil: '/account/informations',

  'Discover our 3 statuses': 'Découvrez nos 3 statuts',
  'With each of your purchases or events, accumulate points and enjoy rewards!':
    'À chacun de vos achats ou évènements, cumulez des points et bénéficiez de récompenses !',
  ESSENTIAL: 'ESSENTIEL',
  PREMIUM: 'PREMIUM',
  'From 0 to 499 points': 'De 0 à 499 points',
  'Loyalty offer': 'Offre fidélité',
  'Birthday offer': 'Offre anniversaire',
  'Invitation to private sales': 'Invitation aux ventes privées',
  '3x interest-free payment': 'Paiement en 3x sans frais',
  '1 simple alteration / year offered': '1 retouche simple /an offerte',
  'And many other surprises': 'Et pleins d’autres surprises',
  'My Membership': 'Rejoindre le Club',
  EXCEPTION: 'EXCEPTION',
  'From 500 to 1999 points': 'De 500 à 1999 points',
  '2 simple alterations / year offered': '2 retouches simples / an offertes',
  'Morphostyle session by appointment': 'Séance de morphostyle sur RDV',
  'Invitation to events': 'Invitation à des évènements',
  'And many other surprises': 'Et pleins d’autres surprises',
  'From 2,000 points': 'Dès 2  000 points',
  'Unlimited touch-ups offered': 'Retouches offertes en illimité',
  'excluding marked down product': 'hors produit démarqué',
  'Free premium delivery': 'Livraison premium offerte',
  'No minimum purchase': 'Sans minimum d’achats',
  'get loyalty points': 'Gagner des points avec les évènements',
  Event: 'Événement',
  All: 'Tous',
  available: 'Disponible',
  expired: 'Expiré',
  used: 'Utilisé',
  'Get the reward': 'Obtenez la récompense',
  'See the reward': 'Voir la récompense',
  'Expired reward': 'Récompense utilisé',
  'Reward used': 'Reward used',
  'Only <b>{0} pts</b> left to obtain this reward':
    'Plus que <b>{0} pts</b> pour obtenir cette récompense',
  Close: 'Fermer',
  'Collect points': 'Gagner des points',
  'Get rewards': 'Obtenir des récompenses',
  'GIFT CARD': 'CARTE CADEAU DE {}',
  'to spend as you wish! Store, website… treat yourself !':
    '{0} à dépenser comme vous le souhaitez ! Magasin, site internet… faites vous plaisir !',
  loyalty_path: 'programme-fidelite',
  points: 'points',
  loyalty_reward_congratulations:
    'Félicitations {name} ! <br /> La récompense est activée sur votre compte.',
  loyalty_reward_body: `<p>Vous pouvez dès à présent en profiter.</p>
                        <p>
                            <i>
                              Retrouvez ci-contre les informations de votre récompense et aussi
                              dans la page mes récompenses pendant la durée de sa validité.
                            </i>
                        </p>`,
  'GIFT CARD': 'CARTE CADEAU',
  Reward: 'Récompense',
  Copy: 'Copier',
  Value: 'Valeur',
  'Valid until': 'Valable jusqu’au',
  'Loyalty program': 'Programme de fidélité',
  Discount: 'Réduction',
  'Get the points': 'Cumuler les points',
  'Event completed': 'Évènement réalisé',
  'Your cart is empty': 'Votre panier est vide',
  "It looks like you haven't added anything to your cart":
    "Il semble que vous n'ayez rien ajouté à votre panier",
  'This product does not exist !': "Ce produit n'existe pas !",

  'Refer a friend': 'Parrainer une amie',
  refer_path: 'parrainer-un-ami',

  refer_stitle_page:
    'Parrainez vos amies et obtenez des réductions sur vos commandes. 15€ offert pour vous et votre amie !',
  refer_step1_title:
    '<strong>Profitez de 15€ de réduction*</strong> <br /> et vos amies aussi. Tout le monde y gagne ! Comment ça marche ?',
  refer_step1_li1: 'Partagez votre lien de parrainage avec vos amies',
  refer_step1_li2:
    'Lorsqu’ils/elles l’utilisent, ils/elles obtiennent 15€ de réduction sur leur première commande',
  refer_step1_li3:
    'Une fois leur commande effectuée, nous vous offrons 15€ à vous aussi. C’est facile !',
  refer_step1_btn_create: 'CRÉER VOTRE COMPTE ET PROFITER DE 15€ OFFERTS',
  refer_step1_btn_offred: 'PROFITER DE 15€ OFFERTS',
  refer_step1_nb: '*Offre valable dès 100€ d’achats.',
  refer_step1_qt: 'En savoir plus sur le programme de parrainage',
  refer_step2_title: 'Enregistrez-vous pour commencer à partager',
  refer_step3_title:
    '<strong>{last_name}, profitez de 15€ de réduction*<br/> </strong>et vos amies aussi. Tout le monde y gagne !',

  refer_form_step_link_title: 'Partagez simplement ce lien avec vos amis :',
  refer_form_step_link_nb:
    'Nous vous avons également envoyé ce lien pour que vous puissiez facilement le transférer à vos amis.',
  refer_form_step_email_header: '<span>DE :</span> <span>{email} </span>',
  refer_form_step_email_body: `<p>Bonjour,</p> 
                                <p> JMP m’a donné une réduction de 15€ à partager avec mes amies pour leur première commande. 
                                Et moi aussi, je profite de 15€. Tout le monde y gagne !</p> 
                                <p>Si tu utilises le lien ci-dessous, tu bénéficieras de 15€ de réductions sur ta première commande JMP. Une fois tes achats effectués, 
                                je bénéficierai moi aussi d’une remise de 15€. Tout le monde y gagne !</p>
                                <p> Voici le lien pour bénéficier des 15€ : <span> {link} </span> </p>`,
  refer_form_step_facebook_title: 'Profite de 15€ de réduction chez JMP',
  refer_form_step_facebook_body:
    'JMP m’a donné une réduction de 15€ à partager avec mes amies pour leur première commande. Et moi aussi, je profite de 15€. Tout le monde y gagne !',
  refer_form_step_btns_copy: 'COPIER LE LIEN',
  refer_form_step_btns_email: 'ENVOYER PAR EMAIL',
  refer_form_step_btns_facebook: 'PARTAGER AVEC FACEBOOK',
  refer_form_step_btns_sms: 'ENVOYER L’OFFRE VIA MA MESSAGERIE',
  refer_form_copy_title: 'VOUS POUVEZ AUSSI PARTAGER UN LIEN :',
  refer_form_step_whatsapp_body: `<p>Bonjour,</p> 
                                  <p> JMP m’a donné une réduction de 15€ à partager avec mes amies pour leur première commande. 
                                  Et moi aussi, je profite de 15€. Tout le monde y gagne !</p> 
                                  <p>Si tu utilises le lien ci-dessous, tu bénéficieras de 15€ de réductions sur ta première commande JMP. Une fois tes achats effectués, 
                                  je bénéficierai moi aussi d’une remise de 15€. Tout le monde y gagne !</p>
                                  <p> Voici le lien pour bénéficier des 15€ : <span> {link} </span> </p>`,
  refer_form_step_btns_whatsapp: 'ENVOYER L’OFFRE VIA MON whatsapp',

  sponsored_page_title: 'Votre amie vous invite',
  sponsored_page_stitle:
    'Profitez de 15€ de réduction* offerts de la part de votre amie ! Une fois votre commande validée, votre amie recevra aussi 15€ de réduction* grâce à vous.',
  sponsored_step1_title:
    '<strong>Profitez de 15€ de réduction*</strong> grâce à votre amie.',
  sponsored_step1_p:
    'Il vous suffit de saisir votre adresse e-mail ci-dessous afin d’obtenir votre code de réduction.',
  sponsored_step1_supmit: 'RECEVOIR 15€ DE RÉDUCTION',
  sponsored_step2_title:
    '<strong>Profitez de 15€ de réduction*</strong> grâce à votre amie.',
  sponsored_step2_p:
    'Voici votre code de réduction afin de bénéficier d’une remise de 15€ sur votre première commande. Cette offre est valide pendant 6 jours, jusqu’au lundi 11 mars 2024. Nous vous avons aussi envoyé cette offre par e-mail.',
  sponsoredForm_cgv:
    '*Oui, j’accepte de m’inscrire au programme de parrainage. En vous enregistrant vous acceptez nos modalités d’utilisation et notre programme de parrainage',
  'learn more': 'En savoir plus',
  sponsored_step1_form_nb:
    'En vous inscrivant vous adhérez gratuitement au programme de parrainage qui vous permet d’obtenir des bons de réductions pour votre prochaine commande.',
  Copied: 'Copié',
  'This code is already used': 'Ce code est déjà utilisé',
  'This code is disabled': 'Ce code est désactivé',

  account_referal_table_title: 'SUIVI DE VOS PARRAINAGES',
  account_referal_table_disc:
    'Parrainez vos amies et obtenez des réductions sur vos commandes. 15€ offert pour vous et votre amie dès qu’il passe commande !',
  waiting: 'En attente',
  registred: 'inscrit',
  won: 'Gagné',
  'invitation sent': 'INVITATION ENVOYÉE',
  'order completed': 'COMMANDE EFFECTUÉE',
  'guest registered': 'INVITÉE INSCRITE',
  Referral: 'PARRAINAGE',
  'Refer friends': 'Parrainer des amies',
  'My referrals': 'Mes parrainages',
  'Track your sponsorships': 'Suivez vos parrainages',
  'Sponsorships made': 'PARRAINAGES EFFECTUÉS',
  'Guest orders': 'COMMANDES INVITÉS',

  'Discount code applied': 'Code réduction appliqué',
  'Apply discount code': 'Appliquer le code réduction',
  'Loyalty: reward unlocked': 'Fidélité : récompense débloquée',
  'Discount code': 'Code de réduction',
  'Apply your loyalty discount codes to your basket.':
    'Appliquer vos codes de réduction fidélité sur votre panier.',

  'My rewards unlocked': 'Mes récompenses débloquées',
  'My rewards': 'Mes récompenses',

  Language: 'Langue',
  'You currently have no returns': "Vous n'avez actuellement aucun retour",
  'You can create a new return from My Orders.':
    'Vous pouvez créer un nouveau retour depuis Mes commandes.',
  'exchanges-and-refunds': 'echanges-et-remboursements',
  'You have no rewards':
    'Cumuler des points fidélité pour débloquer de nouvelles récompenses.',
  until: "jusqu'au",

  'Secure credit card payment':
    'Paiement par carte bancaire sécurisé avec Stripe',
  'Simple, fast and reliable, Stripe protects your transactions with cutting-edge technologies. Buy with confidence!':
    'Simple, rapide et fiable, Stripe protège vos transactions grâce à des technologies de pointe. Achetez en toute confiance !',

  'Order as a guest': "Commander en tant qu'invité",
  'Guest order': 'Commande invité',

  'Return window closed': 'Fenêtre de retour fermée',

  'Discover our universe': 'Découvrir notre univers',

  'Our history ; 40 years of fashion': 'Découvrir notre univers',
  "In line with women's curves": 'En ligne avec les courbes des femmes',
  'Creation with audacity and passion': 'La création avec audace et passion',
  'Responsible manufacturing in France':
    'Une fabrication responsable en France',
  'Our news in the newspaper': 'Notre actualité dans le journal',
  'by discount': 'par remise',
  'By category': 'Par catégorie',
  'Please complete your address by adding a mobile phone number':
    'Veuillez compléter votre adresse en ajoutant un numéro de téléphone mobile',

  state: 'Statut',
  'wallet-dialog-text':
    'Présentez votre carte fidélité en boutique et cumulez des points tout au long de votre expérience et de vos achats. Consultez votre solde de points et convertissez vos points fidélités en réduction.',

  'Authentication by Email/Password has failed or has not been provided.':
    'Email ou mot de passe incorrect',

  'Bank card': 'Carte bancaire',
  'Payment by credit card, Google Pay and Apple Pay.':
    'Paiement par carte bancaire, Google Pay et Apple Pay.',
  'You will be redirected to PayPal to finalize your payment.':
    'Vous serez redirigée sur PayPal pour finaliser votre paiement.',
  'Payment in 3 installments without fees.': 'Paiement en 3 fois sans frais.',

  'delivery between': 'Livraison entre le {from} et le {to}',
  Preorder: 'Pré-commande',
  preorder_readmore: `<span>Pré-commande</span>
                      <ul>
                        <li>Bénéficiez d'une remise de -10% sur votre article en pré-commande.</li>
                        <li>L'article sera expédié dès que nous l'aurons en stock selon le délai de livraison prévu dans la description de l'article. Nous nous réservons le droit d'apporter des modifications et dans ce cas, nous vous tiendrons informé par email.</li>
                        <li>Les options de livraison peuvent être sélectionnées avant le paiement. Si vous commandez plusieurs articles, nous vous expédierons les articles disponibles immédiatement dans un premier envoi, puis les articles en pré-commande ensuite par un nouvel envoi.</li>
                        <li>Votre droit de retour commence à partir du jour où vous recevez l'article.</li>
                      </ul>
                      `,

  how_to_earn_points: 'Comment gagner des points ?',
  join_program: 'Rejoignez notre programme JMP Connect',
  first_250_points_offered: 'vos 250 premiers points offerts',
  welcome_gift: 'Cadeau de bienvenue',
  points: 'Points',
  discount_text:
    'soit {amount} de réduction sur votre prochain achat en magasin ou sur jeanmarcphilippe.com',
  accumulate_points: 'Cumulez des points',
  exchange_points:
    'Disposez de vos points à tout moment et décidez du nombre de points que vous échangez',
  discount: 'de réduction',
  checkout: 'Passage en caisse',
  additional_order_points: 'En plus de vos points de commandes collectés',
  birthday: 'Anniversaire',
  birthday_discount_text:
    'Pendant le mois de votre anniversaire. Utilisable une fois par an hors promotion',
  social_media: 'Réseaux sociaux',
  follow_social_media:
    'Suivez-nous sur Instagram, TikTok, Facebook et cumulez des points',
  register: 'Inscrivez-vous',
  learn_more: 'En savoir plus',
  use_your_points: 'UTILISER VOS POINTS',
  use_points_anytime:
    'Disposez de vos points à tout moment et décidez du nombre de points que vous échangez',
  on_website: 'sur jeanmarcphilippe.com',
  connect_to_access_wallet: 'Pensez',
  connect_to_access_wallet:
    'Pensez bien à vous connecter pour accéder à votre cagnotte sur la page panier.',
  in_stores: 'en boutiques',
  generate_discount_online:
    'Générez directement votre réduction depuis votre cagnotte sur notre site',

  awards_catalog_title: 'Catalogue de récompenses',
  use_points_choose_reward:
    'Utilisez vos points et choisissez la récompense qui vous fait le plus plaisir :',
  reward_types: 'cartes cadeaux, séances de cinéma et bien plus encore !',
  gift_card_20_euro: 'Carte cadeau de 20€',
  gift_card_20_euro_desc:
    '20€ à dépenser comme vous le souhaitez ! Magasin, site internet… faites-vous plaisir !',
  points: 'points',
  get_reward: 'Obtenir la récompense',
  exclusive_rewards_wait: 'DES RÉCOMPENSES EXCLUSIVES VOUS ATTENDENT',
  all: 'Tous',
  gift_cards: 'Cartes cadeaux',
  partner_offers: 'Offres partenaires',
  register: 'M’inscrire',
  learn_more: 'En savoir plus',
  my_membership: 'Mon adhésion',

  join_loyalty_program: 'Rejoignez notre programme de fidélité :',
  first_250_points_offered: 'vos 250 premiers points sont offerts',
  welcome_gift_250_points:
    'Recevez en cadeau de bienvenue 250 points, soit 10€ de réduction sur votre prochain achat en boutique ou sur jeanmarcphilippe.com',
  want_to_know_more: 'Vous voulez en savoir plus ?',

  'My level': 'Mon niveau',
  'you only have {points} points left to reach {status}':
    'Il vous reste plus que <b>{points}</b> points pour atteindre le <b>{status}</b>',

  'With each of your purchases or events, earn points and benefit from rewards!':
    'À chacun de vos achats ou évènements, gagnez des points et bénéficiez de récompenses !',
  'Use your points and choose the reward that makes you the happiest! Your rewards can be used on our site and also in stores':
    'Utilisez vos points et choisissez la récompense qui vous rend le plus heureux ! Vos récompenses sont utilisables sur notre site et également en magasin',

  //Routing -----------------------
  referal_path_faq: '/content/faq/parrainer-une-amie',
};

// export {
//   FR
// };
export default async (context, locale) => {
  return await Promise.resolve(FR);
};
