import { AppService } from './AppService';
import { FavoriteService } from './FavoriteService';
import { CartService } from './CartService';

export default class CustomerService extends AppService {
  constructor({ http, auth, store }) {
    super({ http, auth, store });
    this.favoriteService = new FavoriteService({ http, auth, store });
    this.cartService = new CartService({ http, auth, store });
  }

  async edit({ customer }) {
    var qs = require('qs');
    var data = qs.stringify({
      Firstname: customer.firstName,
      Lastname: customer.lastName,
      Birthday: customer.birthDate,
      LanguageIsoCode: customer.languageIsoCode,
      CurrencyIsoCode: customer.currencyIsoCode,
    });

    try {
      const response = await this.http.API_AUTH.put('customer', data);
      if (response.status === 200) {
        this.auth.fetchUser();
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async initCustomerData() {
    this.cartService.removeIdCartFromCookies();
    this.favoriteService.removeIdFavoriteFromCookies();
    try {
      const res = await this.auth.fetchUser();
      await this.cartService.fetchCart();
      await this.favoriteService.fetchFavorite();
    } catch (error) {}
  }

  async register(
    {
      email,
      firstname,
      lastname,
      password,
      birthdate,
      active,
      newletterActive,
      loyaltySubscribe,
      languageIsoCode,
      currencyIsoCode,
    },
    successCallBack = () => {}
  ) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    formData.append('Firstname', firstname);
    formData.append('Lastname', lastname);
    formData.append('Password', password);
    formData.append('Birthday', birthdate);
    formData.append('Active', active);
    formData.append('NewletterActive', newletterActive);
    formData.append('LoyaltySubscribe', loyaltySubscribe);
    formData.append('LanguageIsoCode', languageIsoCode);
    formData.append('CurrencyIsoCode', currencyIsoCode);
    let idCart = this.cartService.idCart;
    let idFavorite = this.favoriteService.idFavorite;
    if (idCart != null) {
      formData.append('IdCart', idCart);
    }
    if (idFavorite != null) {
      formData.append('IdFavorite', idFavorite);
    }

    try {
      let response = await this.http.API.post('customer', formData);
      if (response.status === 200) {
        this.auth.setUser(response.data.AuthData.Customer);
        await this.auth.setUserToken(response.data.AuthData.Token);
        await this.initCustomerData();
        await successCallBack();
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async registerGuest({ account, languageIsoCode, currencyIsoCode }) {
    var FormData = require('form-data');
    var formData = new FormData();

    formData.append('Email', account.email);
    formData.append('Firstname', account.firstname);
    formData.append('Lastname', account.lastname);
    formData.append('Guest', true);

    formData.append('Address1', account.addressInfo.Address1);
    formData.append('Address2', account.addressInfo.Address2);
    formData.append('Postcode', account.addressInfo.Postcode);
    formData.append('City', account.addressInfo.City);
    formData.append('CountryIsoCode', account.addressInfo.CountryIsoCode);
    formData.append('MobilePhone', account.addressInfo.MobilePhone);
    formData.append('IsInvoice', true);
    formData.append('IsDelivery', true);

    formData.append('LanguageIsoCode', languageIsoCode);
    formData.append('CurrencyIsoCode', currencyIsoCode);
    let idCart = this.cartService.idCart;
    let idFavorite = this.favoriteService.idFavorite;

    if (idCart != null) {
      formData.append('IdCart', idCart);
    }
    if (idFavorite != null) {
      formData.append('IdFavorite', idFavorite);
    }

    try {
      let response = await this.http.API.post('customer', formData);
      if (response.status === 200) {
        // this.auth.setUser(response.data.AuthData.Customer);
        // await this.auth.setUserToken(response.data.AuthData.Token);
        // await this.initCustomerData();
        // await successCallBack();
        this.cartService.removeIdCartFromCookies();
        this.favoriteService.removeIdFavoriteFromCookies();
        this.store.dispatch('user/guest/setGuestUser', {
          user: response.data.AuthData,
          cookies: this.auth.ctx.$cookies,
        });

        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async loginWithEmailAndPassword({ user, password }) {
    var formData = new FormData();
    formData.append('Email', user);
    formData.append('Password', password);
    let idCart = this.cartService.idCart;
    let idFavorite = this.favoriteService.idFavorite;

    if (idCart != null) {
      formData.append('IdCart', idCart);
    }

    if (idFavorite != null) {
      formData.append('IdFavorite', idFavorite);
    }

    try {
      let response = await this.auth.loginWith('local', { data: formData });
      if (response.status === 200) {
        this.initCustomerData();
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async loginWithGoogle({
    googleCustomerId,
    email,
    firstName,
    lastName,
    languageIsoCode,
    currencyIsoCode,
  }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    formData.append('Firstname', firstName);
    formData.append('Lastname', lastName);
    formData.append('GoogleCustomerId', googleCustomerId);
    formData.append('LanguageIsoCode', languageIsoCode);
    formData.append('CurrencyIsoCode', currencyIsoCode);

    let idCart = this.cartService.idCart;
    let idFavorite = this.favoriteService.idFavorite;
    if (idCart != null) {
      formData.append('IdCart', idCart);
    }
    if (idFavorite != null) {
      formData.append('IdFavorite', idFavorite);
    }
    try {
      let response = await this.http.API.post(
        'authentification/google',
        formData
      );
      if (response.status === 200) {
        this.auth.setUser(response.data.AuthData.Customer);
        await this.auth.setUserToken(response.data.AuthData.Token);
        this.initCustomerData();

        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async loginWithFacebook({
    facebookCustomerId,
    email,
    firstName,
    lastName,
    languageIsoCode,
    currencyIsoCode,
  }) {
    var FormData = require('form-data');
    var formData = new FormData();
    formData.append('Email', email);
    formData.append('Firstname', firstName);
    formData.append('Lastname', lastName);
    formData.append('FacebookCustomerId', facebookCustomerId);
    formData.append('LanguageIsoCode', languageIsoCode);
    formData.append('CurrencyIsoCode', currencyIsoCode);

    let idCart = this.cartService.idCart;
    let idFavorite = this.favoriteService.idFavorite;
    if (idCart != null) {
      formData.append('IdCart', idCart);
    }
    if (idFavorite != null) {
      formData.append('IdFavorite', idFavorite);
    }
    try {
      let response = await this.http.API.post(
        'authentification/facebook',
        formData
      );
      if (response.status === 200) {
        this.auth.setUser(response.data.AuthData.Customer);
        await this.auth.setUserToken(response.data.AuthData.Token);
        this.initCustomerData();

        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async loginAuto({ token, email }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();

      formData.append('Email', email);
      formData.append('Token', token);

      const response = await this.http.API_AUTH.post(
        `customer/autologin`,
        formData
      );
      if (response.status === 200) {
        this.auth.setUser(response.data.AuthData.Customer);
        await this.auth.setUserToken(response.data.AuthData.Token);
        this.initCustomerData();
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async logOut() {
    this.cartService.clearCart();
    this.favoriteService.clearFavorite();
  }
}
