import { CarriersService } from "@/services/CarriersService.js";

export const state = () => ({
  CarrierTypes: [],
  Carrier: {},
  Selected: null,
  activeTab: "",
  RelayPoint: null,
});

export const mutations = {
  setCarrierTypes(state, { data }) {
    state.CarrierTypes = data.Carriers;
  },
  // setAll(state, { data }) {
  //   state.Carriers = data.Carriers;
  //   state.Carrier = data.Carrier;
  // },
  setCarrier(state, { data }) {
    state.Carrier = { ...state.Carrier, ...data.Carrier };
  },
  setActiveTab(state, val) {
    state.activeTab = val;
  },
  setRelayPoint(state, val) {
    state.RelayPoint = val;
  },
};

export const actions = {
  fetchCarrierTypes(
    { commit },
    { idAddress, onSuccess = () => {}, onError = () => {} }
  ) {
    const carriersService = new CarriersService({
      http: this.$http,
      auth: this.$auth,
    });

    carriersService
      .getCarriers({
        idAddress,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        responseLevel: "summary",
      })
      .then((data) => {
        commit("setCarrierTypes", { data });
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
  fetchCarriers(
    { commit },
    {
      idAddress,
      carrierType,
      responseLevel,
      onSuccess = () => {},
      onError = () => {},
    }
  ) {
    const carriersService = new CarriersService({
      http: this.$http,
      auth: this.$auth,
    });

    carriersService
      .getCarriers({
        idAddress,
        carrierType,
        responseLevel,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        commit("setCarrier", { data });
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
};
