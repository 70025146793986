export class AddressService {
  static ID_ADDRESS_DELIVERY = "ID_ADDRESS_DELIVERY";
  static ID_ADDRESS_INVOICE = "ID_ADDRESS_INVOICE";

  constructor({ http, auth, store }) {
    this.http = http;
    this.auth = auth;
    this.store = store;
  }

  get addresses() {
    return this.store.state.address;
  }

  get addressDelivery() {
    if (this.addresses.List.length === 0) return null;
    else {
      return (
        this.addresses.List.find((item) => item.IsDelivery === true) ||
        this.addresses.List[0]
      );
    }
  }

  get addressInvoice() {
    if (this.addresses.List.length === 0) return null;
    else {
      return (
        this.addresses.List.find((item) => item.IsInvoice === true) ||
        this.addressDelivery
      );
    }
  }

  get idAddressDelivery() {
    return this.addressDelivery.IdAddress;
  }

  get idAddressInvoice() {
    return this.addressInvoice.IdAddress;
  }

  get httpApi() {
    return this.http.API_AUTH;
  }

  fetchAddresses({ onSuccess, onError } = {}) {
    this.store.dispatch("address/fetchAddresses", { onSuccess, onError });
  }

  async getAddresses() {
    let url = `customer/address`; //`?CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}&ResponseLevel=complete`;

    const response = await this.httpApi.get(url);
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async add(address) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("Alias", address.Alias);
    formData.append("Lastname", address.Lastname);
    formData.append("Firstname", address.Firstname);
    formData.append("Address1", address.Address1);
    formData.append("Address2", address.Address2);
    formData.append("Postcode", address.Postcode);
    formData.append("City", address.City);
    formData.append("CountryIsoCode", address.CountryIsoCode);
    formData.append("MobilePhone", address.MobilePhone);
    formData.append("Phone", address.Phone);
    formData.append("IsInvoice", address.IsInvoice);
    formData.append("IsDelivery", address.IsDelivery);

    try {
      const response = await this.http.API_AUTH.post(
        "customer/address/add",
        formData
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async update(idAddress, address) {
    var qs = require("qs");
    var data = qs.stringify({
      IdAddress: idAddress,
      Alias: address.Alias,
      Lastname: address.Lastname,
      Firstname: address.Firstname,
      Address1: address.Address1,
      Address2: address.Address2,
      Postcode: address.Postcode,
      City: address.City,
      CountryIsoCode: address.CountryIsoCode,
      MobilePhone: address.MobilePhone,
      Phone: address.Phone,
      IsInvoice: address.IsInvoice,
      IsDelivery: address.IsDelivery,
    });

    try {
      const response = await this.http.API_AUTH.put(
        "customer/address/update",
        data
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async updateAddressType({ idAddress, isDelivery, isInvoice }) {
    var qs = require("qs");
    var data = qs.stringify({
      IdAddress: idAddress,
      IsInvoice: isInvoice,
      IsDelivery: isDelivery,
    });

    try {
      const response = await this.http.API_AUTH.put(
        "customer/address/type",
        data
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // async setIdAddressDelivery(idAddress) {
  //   var FormData = require("form-data");
  //   var formData = new FormData();
  //   formData.append("IdAddress", idAddress);
  //   formData.append("DefaultDeliveryAddress", 1);
  //   formData.append("DefaultInvoiceAddress", 0);

  //   try {
  //     let response = await this.httpApi.post(
  //       "customer/address/default",
  //       formData
  //     );

  //     if (response.status === 200) {
  //       const { data } = response;
  //       return data;
  //     } else {
  //       throw new Error(`Unexpected status code: ${response.status}`);
  //     }
  //   } catch (error) {
  //     throw new Error(error.message);
  //   }
  // }
  // async setIdAddressInvoice(idAddress, { isDelivery, isInvoice }) {
  //   var FormData = require("form-data");
  //   var formData = new FormData();
  //   formData.append("IdAddress", idAddress);
  //   formData.append("DefaultDeliveryAddress", isDelivery);
  //   formData.append("DefaultInvoiceAddress", isInvoice);

  //   try {
  //     let response = await this.httpApi.post(
  //       "customer/address/default",
  //       formData
  //     );

  //     if (response.status === 200) {
  //       const { data } = response;
  //       return data;
  //     } else {
  //       throw new Error(`Unexpected status code: ${response.status}`);
  //     }
  //   } catch (error) {
  //     throw new Error(error.message);
  //   }
  // }
}
