export class LocalisationInject {
  constructor(localisationHelper) {
    this.localisationHelper = localisationHelper;

    // this.localisationHelper.init();
  }

  get localisation() {
    const $this = this;
    return {
      get countries() {
        return $this.localisationHelper.getCountries();
      },
      get appCountry() {
        return $this.localisationHelper.getAppCountry();
      },
      get appCurrency() {
        return $this.localisationHelper.getAppCurrency();
      },
      get currencySign() {
        return $this.localisation.appCurrency.CurrencySign;
      },
    };
  }

  get toCurrency() {
    return (amount) => {
      const appCurrency = this.localisationHelper.getAppCurrency();
      const currencyConversionRate = Number(appCurrency.CurrencyConversionRate);
      return Number(amount) * currencyConversionRate;
    };
  }

  get toEuro() {
    return (amount) => {
      const appCurrency = this.localisationHelper.getAppCurrency();
      const currencyConversionRate = Number(appCurrency.CurrencyConversionRate);
      return Number(amount) / currencyConversionRate;
    };
  }

  get productPrice() {
    return (priceTaxExcl, fixed = 2) => {
      const appCountry = this.localisationHelper.getAppCountry();
      if (appCountry) {
        const tax = Number(appCountry.Tax.TaxRate);
        const priceTaxIncl = Number(priceTaxExcl) * tax + Number(priceTaxExcl);
        if (fixed === false) {
          return this.toCurrency(priceTaxIncl);
        } else {
          return this.toCurrency(priceTaxIncl).toFixed(fixed);
        }
      }
      return 0;
    };
  }

  get addTax() {
    return (priceTaxExcl) => {
      const appCountry = this.localisationHelper.getAppCountry();
      if (appCountry) {
        const tax = Number(appCountry.Tax.TaxRate);
        const priceTaxIncl = Number(priceTaxExcl) * tax + Number(priceTaxExcl);
        return priceTaxIncl;
      }
      return 0;
    };
  }
}
