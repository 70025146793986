import userGuest from './app/userGuest';

export default (context, inject) => {
  const URL = context.env.URL;
  // const URL = 'http://localhost:3000';

  const API = context.$axios.create({
    baseURL: URL + 'api_dev/',
  });

  const API2 = context.$axios.create({
    baseURL: 'https://app.digipart.fr/api2/',
  });

  const API_AUTH = context.$axios.create({
    baseURL: URL + 'api_dev/',
  });

  API_AUTH.onRequest((config) => {
    let token = '';
    let userGuest = context.store.state.user.guest.guestUser;


    if (context.$auth.loggedIn) {
      token = context.$auth.strategy.token.get();
    } else if (userGuest) {
      
      token = userGuest.Token;
    }

   
    config.headers.Authorization = token;
    return config;
  });

  const HIPAY_URL = context.env.HIPAY_URL;
  const HIPAY_API = context.$axios.create({
    baseURL: HIPAY_URL,
  });

  //Alma
  const ALMA = context.$axios.create({
    baseURL: context.env.ALMA_URL,
  });
  ALMA.onRequest((config) => {
    config.headers.Authorization = `Alma-Auth ${context.env.ALMA_TOKEN}`;
    return config;
  });

  //Klarna
  const KLARNA = context.$axios.create({
    baseURL: URL + 'api_klarna/',
  });
  const username = context.env.KLARNA_USERNAME;
  const password = context.env.KLARNA_PASSWORD;

  // Create a Basic Authentication header by encoding the username and password
  const basicAuthHeader =
    'Basic ' + Buffer.from(`${username}:${password}`).toString('base64');

  KLARNA.onRequest((config) => {
    config.headers.Authorization = basicAuthHeader;
    config.headers['Content-Type'] = 'application/json';
    return config;
  });

  inject('http', {
    API,
    API_AUTH,
    API2,
    HIPAY_API,
    ALMA,
    KLARNA,
  });
};
