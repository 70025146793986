//add product to cart

export class AddressHelpers {
  constructor(http, store) {
    // this.context = context;
    this.http = http;
    this.store = store;
  }

  async getList() {
    try {
      const response = await this.http.API_AUTH.get("customer/address");

      if (response.status === 200) {
        const { data } = response;
        this.store.commit("user/address/initialiseStore", data);
        return data;
      }
      return [];
    } catch (error) {
      return error;
    }
  }

  async create(address) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("Alias", address.Alias);
    formData.append("Company", address.Company);
    formData.append("Lastname", address.Lastname);
    formData.append("Firstname", address.Firstname);
    formData.append("Address1", address.Address1);
    formData.append("Address2", address.Address2);
    formData.append("Postcode", address.Postcode);
    formData.append("City", address.City);
    // formData.append("Country", address.Country);
    formData.append("CountryIsoCode", address.CountryIsoCode);
    formData.append("MobilePhone", address.MobilePhone);
    formData.append("Phone", address.Phone);
    formData.append("IsInvoice", address.IsInvoice ? 1 : 0);
    formData.append("IsDelivery", address.IsDelivery ? 1 : 0);

    try {
      const response = await this.http.API_AUTH.post(
        "customer/address/add",
        formData
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async update(idAddress, address) {
    var qs = require("qs");
    var data = qs.stringify({
      IdAddress: idAddress,
      Alias: address.Alias,
      Company: address.Company,
      Lastname: address.Lastname,
      Firstname: address.Firstname,
      Address1: address.Address1,
      Address2: address.Address2,
      Postcode: address.Postcode,
      City: address.City,
      CountryIsoCode: address.CountryIsoCode,
      // Country: address.Country,
      MobilePhone: address.MobilePhone,
      Phone: address.Phone,
      IsInvoice: address.IsInvoice,
      IsDelivery: address.IsDelivery,
    });
    // var FormData = require("form-data");
    // var formData = new FormData();
    // formData.append("IdAddress", idAddress);
    // formData.append("Alias", address.Alias);
    // formData.append("Company", address.Company);
    // formData.append("Lastname", address.Lastname);
    // formData.append("Firstname", address.Firstname);
    // formData.append("Address1", address.Address1);
    // formData.append("Address2", address.Address2);
    // formData.append("Postcode", address.Postcode);
    // formData.append("City", address.City);
    // // formData.append("Country", address.Country);
    // formData.append("CountryIsoCode", address.CountryIsoCode);
    // formData.append("MobilePhone", address.MobilePhone);
    // formData.append("Phone", address.Phone);

    this.http.API_AUTH.put("customer/address/update", data)
      .then((res) => {
        if (res.Status === "Success") {
          this.getList();
        }
        this.context.$router.push({
          path: this.context.localePath("/account/address"),
        });
      })
      .catch((error) => {
        // error.response.status Check status code
      })
      .finally(() => {
        //Perform action in always
      });
  }

  updatDefaultAddress(address, defaultDeliveryAddress, defaultInvoiceAddress) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("IdAddress", address.IdAddress);
    formData.append("DefaultDeliveryAddress", defaultDeliveryAddress);
    formData.append("DefaultInvoiceAddress", defaultInvoiceAddress);

    this.http.API_AUTH.$post("customer/address/default", formData)
      .then((res) => {
        if (res.Status === "Success") {
          this.getList();
        }
      })
      .catch((error) => {
        // error.response.status Check status code
      })
      .finally(() => {
        //Perform action in always
      });
  }
}
