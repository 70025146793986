import { AppService } from "./AppService";

export default class AccountService extends AppService {
  constructor({ http, auth, store }) {
    super({ http, auth, store });
  }

  get account() {
    return { ordersData: this.store.state.account.orders.data };
  }

  fetchOrders({ onSuccess, onError } = {}) {
    this.store.dispatch("account/orders/fetchOrders", {
      onSuccess,
      onError,
    });
  }

  async getOrder({ idOrder, languageIsoCode, currencyIsoCode }) {
    let url = `order/detail?IdOrder=${idOrder}&CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}`;

    const response = await this.http.API_AUTH.get(url);
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async getOrderList({ languageIsoCode, currencyIsoCode }) {
    let url = `order/list?CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}`;

    const response = await this.http.API_AUTH.get(url);
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
}
