export class CartService {
  static ID_CART = 'ID_CART';

  constructor({ http, auth, store }) {
    this.http = http;
    this.auth = auth;
    this.store = store;
  }

  get cart() {
    return this.store.state?.cart;
  }

  get userGuest() {
    let user = this.auth.ctx.store.state.user.guest.guestUser;
    return user;
  }

  get idCart() {
    let id = null;

    if (this.auth.loggedIn) {
      if (this.auth.user.Customer.IdCart) {
        id = this.auth.user.Customer.IdCart;
      }
    } else if (this.userGuest) {

      if (this.userGuest.Customer.IdCart) {
        id = this.userGuest.Customer.IdCart;
      }
    } else {
      const cookies = this.auth.ctx.$cookies;
      id = cookies.get(CartService.ID_CART);
    }
    return id;
  }

  get httpApi() {
    let api = this.http.API;
    if (this.auth.loggedIn || this.userGuest) {
      api = this.http.API_AUTH;
    }

    return api;
  }

  hasCodePromo(code) {
    try {
      return this.cart?.Discounts?.PromoCodes?.find(
        (item) => item?.Code.toLocaleLowerCase() === code.toLocaleLowerCase()
      );
    } catch (error) {
      return false;
    }
  }

  setIdCartToCookies(idCart) {
    const cookies = this.auth.ctx.$cookies;
    cookies.set(CartService.ID_CART, idCart, {
      path: '/',
      maxAge: 60 * 60 * 24,
    });
  }

  removeIdCartFromCookies() {
    const cookies = this.auth.ctx.$cookies;
    cookies.remove(CartService.ID_CART, {
      path: '/',
      maxAge: 60 * 60 * 24,
    });
  }

  fetchCart({ languageIsoCode, onSuccess, onError } = {}) {
    this.store.dispatch('cart/fetchCart', {
      languageIsoCode,
      onSuccess,
      onError,
    });
  }
  fetchCartSummary({ languageIsoCode, onSuccess, onError } = {}) {
    this.store.dispatch('cart/fetchCartSummary', {
      languageIsoCode,
      onSuccess,
      onError,
    });
  }

  clearCart() {
    this.removeIdCartFromCookies();
    this.store.commit('cart/clear');
  }

  async initNewCart() {
    try {
      let res = await this.http.API_AUTH.$get('cart/new');
      if (res.IdCart) {
        return res;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async updateCartId() {
    try {
      let res = await this.http.API_AUTH.$post('cart/updateId');
      if (res.NewIdCart) {
        return res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getCartAll({ languageIsoCode, currencyIsoCode, countryIsoCode }) {
    let url = `cart?CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}&CountryIsoCode=${countryIsoCode}&ResponseLevel=complete`;
    let id = this.idCart;
    let api = this.httpApi;
    if (id) {
      url += `&IdCart=${id}`;
      const response = await api.get(url);
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } else {
      throw new Error(`IdCart undefined`);
    }
  }
  async getCartSummary({ languageIsoCode, currencyIsoCode, countryIsoCode }) {
    let url = `cart?CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}&CountryIsoCode=${countryIsoCode}&ResponseLevel=summary`;
    let id = this.idCart;
    let api = this.httpApi;
    if (id) {
      url += `&IdCart=${id}`;
      const response = await api.get(url);
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } else {
      throw new Error(`IdCart undefined`);
    }
  }

  async addToCart({ idCart, product, idProductAttribute, quantity = 1 }) {
    var FormData = require('form-data');
    var formData = new FormData();
    if (this.idCart) {
      formData.append('IdCart', this.idCart);
    }
    formData.append('IdProduct', product.IdProduct);
    formData.append('Quantity', quantity);
    formData.append('IdProductAttribute', idProductAttribute);

    try {
      let response = await this.httpApi.post('cart/product/add', formData);

      if (response.status === 200) {
        const { data } = response;
        if (!this.auth.loggedIn) {
          this.setIdCartToCookies(data.IdCart);
        }

        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  async updateProductCart({
    idCart,
    product,
    quantity = 1,
    newIdProductAttribute,
  }) {
    const qs = require('qs');
    let dataQS = qs.stringify({
      IdCart: idCart,
      IdProduct: product.IdProduct,
      IdProductAttribute: product.IdProductAttribute,
      Quantity: quantity,
      NewIdProductAttribute: newIdProductAttribute,
    });
    try {
      let response = await this.httpApi.put('cart/product/update', dataQS);
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async removeProductCart({ idCart, product }) {
    const qs = require('qs');
    let dataQS = qs.stringify({
      IdCart: idCart,
      IdProduct: product.IdProduct,
      IdProductAttribute: product.IdProductAttribute,
    });
    try {
      let response = await this.httpApi.delete('cart/product/delete', {
        data: dataQS,
      });
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  async setCarrierToCart({ idCarrier, idRelayPoint }) {
    const qs = require('qs');
    const params = {
      IdCarrier: idCarrier,
      IdCart: this.idCart,
    };
    if (idRelayPoint) {
      params.IdRelayPoint = idRelayPoint;
    }
    let dataQS = qs.stringify(params);

    try {
      let response = await this.httpApi.put('cart/carrier', dataQS);

      if (response.status === 200) {
        const { data } = response;

        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  purchaseItems() {
    const items = [];

    this.cart?.Products?.map((item) => {
      let promotionalPrice = item?.Price?.PromotionalPrice;
      let regularPrice = item?.Price?.RegularPrice;
      let price = regularPrice;

      if (promotionalPrice) {
        price = promotionalPrice;
      }
      items.push({
        item_id: item.IdProduct,
        item_name: item.Description.Title,
        price: price.PriceTaxIncl,
        quantity: item.Quantity,
      });
    });
    return items;
  }
  googlePurchase(orderid, gtag) {
    const purchaseOption = {
      transaction_id: orderid,
      affiliation: 'Google online store - ' + orderid,
      shipping: this.cart?.Total?.Shipping?.TaxIncl,
      currency: this.cart?.Currency?.IsoCode,
      tax: this.cart?.Country?.TaxRate,
      value: this.cart?.Total?.ToPay?.TaxIncl,
      items: this.purchaseItems(),
    };
    gtag.purchase(purchaseOption);
  }
  facebookPurchase(orderid) {
    fbq('track', 'Purchase', {
      content_ids: [orderid],
      eventref: '', // or set to empty string
      currency: this.cart?.Currency?.IsoCode, // your currency string value goes here
      num_items: this.purchaseItems().length, // your number of tickets purchased value goes here
      value: this.cart?.Total?.ToPay?.TaxIncl, // your total transaction value goes here
    });
  }
  async finishOrder(orderid, gtag, state) {
    if (state === 'accepted') {
      this.googlePurchase(orderid, gtag);
      this.facebookPurchase(orderid);

      if (this.auth.loggedIn) {
        await this.initNewCart();
        await this.auth.fetchUser();
        await this.fetchCart();
      }
    } else {
      await this.updateCartId();
      await this.auth.fetchUser();
      await this.fetchCart();
    }
    this.store.dispatch('shop/tunnel/order/set', true);
  }

  //code promo

  async applyPromoCode({
    code = null,
    idCart,
    loyaltyRewardStateLabel = null,
  }) {
    var FormData = require('form-data');
    var formData = new FormData();
    if (code) {
      formData.append('PromoCode', code);
    }
    if (loyaltyRewardStateLabel) {
      formData.append('LoyaltyRewardStateLabel', loyaltyRewardStateLabel);
    }
    formData.append('IdCart', idCart);

    const response = await this.http.API_AUTH.post(
      `cart/promocode/add`,
      formData
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async removeCodePromo({ idCart, idPromoCode }) {
    const qs = require('qs');
    let dataQS = qs.stringify({
      IdCart: idCart,
      IdPromoCode: idPromoCode,
    });
    const response = await this.http.API_AUTH.delete(`cart/promocode/delete`, {
      data: dataQS,
    });
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
}
