export class ImageProductSizesInject {

  constructor(path) {
    this.path = path
  }

  // Cart & mini cart
  get xs() {
    return {
      w: 175,
      h: 263,
    };
  }

  // favorite & order
  get sm() {
    return {
      w: 350,
      h: 525,
    };
  }

  // catalogue
  get md() {
    return {
      w: 500,
      h: 750,
    };
  }

  // page product
  get lg() {
    return {
      w: 750,
      h: 1125,
    };
  }

  // zoom
  get xl() {
    return {
      w: 2000,
      h: 3000,
    };
  }

  // original
  get xxl() {
    return {
      w: 3000,
      h: 4500,
    };
  }

  image(url, size, type = 'jpg') {

    const words = url.split('/')
    const imageName = words[words.length - 1].replace('thumbnail-', '')

    let dimentions
    switch (size) {
      case "xs":
        dimentions = `${this.xs.w}x${this.xs.h}`
        break;
      case "sm":
        dimentions = `${this.sm.w}x${this.sm.h}`
        break;
      case "lg":
        dimentions = `${this.lg.w}x${this.lg.h}`
        break;
      case "xl":
        dimentions = `${this.xl.w}x${this.xl.h}`
        break;
      case "xxl":
        dimentions = `${this.xxl.w}x${this.xxl.h}`
        break;
      default:
        dimentions = `${this.md.w}x${this.md.h}`
        break;
    }
    if (imageName === "default-img.jpg") {
      return url
    }
    const src = `${this.path}filters:format(${type})/fit-in/${dimentions}/filters:quality(100)/upload/images/retouched/${imageName}`
    return src
  }

}
