export class AppInfo {


  get socialMedia() {
    return {
      facebook: "https://www.facebook.com/JMPParis/",
      instagram: "https://www.instagram.com/jmp_paris/",
      youtube: "https://www.youtube.com/@jmp-paris1744",
      twitter: "",
    }
  }


}
