export const state = () => ({
  firstLoad: false,
});

export const mutations = {
  set(state, value) {
    state.value = value;
  },
};

export const actions = {
  fetchCartSummary({ commit }, value) {
    commit('set', value);
  },
};
