export const state = () => ({
  data: {},
})


export const mutations = {
  setData(state, data) {
    state.data = data;
  },

}
