import { ProductHelpers } from "@/helpers/ProductHelpers.js";

export const state = () => ({
  products: [],
  currentCategory: null,
  categoryNotFound: false,
  permalink: null,
  page: 1,
  noMoreProduct: false,
  filterColors: [],
  filterSizes: [],
  priceMin: 0,
  priceMax: 0,
});

export const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  loadProducts(state, { products, before = false }) {
    if (before === true) {
      state.products = [...products, ...state.products];
    } else {
      state.products = [...state.products, ...products];
    }
  },
  setCurrentCategory(state, { category, permalink }) {
    state.currentCategory = category;
    state.permalink = permalink;
  },
  setCategoryNotFound(state) {
    state.currentCategory = null;
    state.categoryNotFound = true;
  },
  setCategoryFilters(state, filters) {
    state.filterColors = filters.Colors;
    state.filterSizes = filters.Sizes;
    state.priceMin = Number(filters.PriceRange.Min);
    state.priceMax = Number(filters.PriceRange.Max);
  },
  setPage(state, nbr) {
    state.page = nbr;
  },
  pageUp(state) {
    state.page += 1;
  },
  pageDown(state) {
    state.page -= 1;
  },
  setNoMoreProduct(state, nbrProduct) {
    const offset = this.$GLOBAL_VARIABLES.categoryNuberPerPage;

    let nbrPages = nbrProduct / offset;
    if (nbrProduct % offset !== 0) {
      nbrPages += 1;
    }
    nbrPages = Math.floor(nbrPages);
    state.noMoreProduct = state.page >= nbrPages;
  },
  init(state) {
    state.products = [];
    state.currentCategory = null;
    state.categoryNotFound = false;
    // state.page = 1;
  },
};

export const actions = {
  async fetchCategory(
    { commit, state },
    { permalink, filterData = null, sortby = null, refresh = false, page }
  ) {
  

    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    try {
      const response = await productHelpers.getListByCategory2({
        permalink,
        page: state.page,
        filterData,
        sortby,
      });
      if (response.status === 200) {
        commit("init");
        const category = response.data[0];

        commit("setCurrentCategory", { category, permalink });
        commit("setProducts", category.Products);
        commit("setNoMoreProduct", category.NumberOfProducts);
        commit("setCategoryFilters", category.Filters);
      } else {
        commit("setCategoryNotFound");
      }
      if (response.data.error) {
        commit("setCategoryNotFound");
      }
    } catch (error) {
      commit("setCategoryNotFound");
    }
  },

  async loadMoreProductCategory(
    { commit, state },
    { permalink, filterData = null, sortby = null }
  ) {
    commit("pageUp");
    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    try {
      const response = await productHelpers.getListByCategory2({
        permalink,
        page: state.page,
        filterData,
        sortby,
      });
      if (response.status === 200) {
        const category = response.data[0];
        commit("loadProducts", { products: category.Products });
        commit("setNoMoreProduct", category.NumberOfProducts);
      } else {
      }
      if (response.data.error) {
      }
    } catch (error) {}
  },

  async loadLessProductCategory(
    { commit, state },
    { permalink, filterData = null, sortby = null }
  ) {
    commit("pageDown");
    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    try {

      const response = await productHelpers.getListByCategory2({
        permalink,
        page: state.page,
        filterData,
        sortby,
      });
      if (response.status === 200) {
        const category = response.data[0];
        commit("loadProducts", { products: category.Products, before: true });
      } else {
      }
      if (response.data.error) {
      }
    } catch (error) {}
  },
};
