import Vue from 'vue'
import {
  Navigation,
  Pagination
} from 'swiper'

import {
  SwiperCore,
  Swiper,
  SwiperSlide
} from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])


Vue.component('Swiper', Swiper)
Vue.component('SwiperSlide', SwiperSlide)
