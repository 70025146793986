export const state = () => ({
  currentAddress: null,
  currentInvoiceAddress: null,
  deliveryOption: null,
  carrier: null,
  relayPoint: null,
});

const CURRENT_ADDRESS = "CURRENT_ADDRESS";
const CURRENT_INVOICE_ADDRESS = "CURRENT_INVOICE_ADDRESS";
const DELVERY_OPTION = "DELVERY_OPTION";
const CARRIER = "CARRIER";
const RELAY_POINT = "RELAY_POINT";

export const mutations = {
  setCurrentAddress(state, { $auth, address }) {
    state.currentAddress = address;
    $auth.$storage.setUniversal(CURRENT_ADDRESS, address);
  },

  setCurrentInvoiceAddress(state, { $auth, address }) {
    state.currentInvoiceAddress = address;
    $auth.$storage.setUniversal(CURRENT_INVOICE_ADDRESS, address);
  },

  setDeliveryOption(state, { $auth, deliveryOption }) {
    let deliveryOptionCopy = deliveryOption;
    state.deliveryOption = deliveryOption;

    if (deliveryOption) {
      deliveryOptionCopy = {
        ...deliveryOption,
      };
      delete deliveryOptionCopy.RelayPoints;
    }
    $auth.$storage.setUniversal(DELVERY_OPTION, deliveryOptionCopy);
  },

  setRelayPoint(state, { $auth, relayPoint }) {
    state.relayPoint = relayPoint;
    $auth.$storage.setUniversal(RELAY_POINT, relayPoint);
  },

  setCarrier(state, { $auth, carrier }) {
    state.carrier = carrier;
    $auth.$storage.setUniversal(CARRIER, carrier);
  },

  initialiseStore(state, $auth) {
    try {
      let currentAddressStorage = $auth.$storage.getUniversal(CURRENT_ADDRESS);
      let currentInvoiceAddressStorage = $auth.$storage.getUniversal(
        CURRENT_INVOICE_ADDRESS
      );
      let deliveryOptionStorage = $auth.$storage.getUniversal(DELVERY_OPTION);
      let carrierStorage = $auth.$storage.getUniversal(CARRIER);
      let relayPointStorage = $auth.$storage.getUniversal(RELAY_POINT);

      if (currentAddressStorage === undefined) {
        state.currentAddress = null;
      } else {
        state.currentAddress = currentAddressStorage;
      }
      if (currentInvoiceAddressStorage === undefined) {
        state.currentInvoiceAddress = null;
      } else {
        state.currentInvoiceAddress = currentInvoiceAddressStorage;
      }

      if (deliveryOptionStorage === undefined) {
        state.deliveryOption = null;
      } else {
        state.deliveryOption = deliveryOptionStorage;
      }

      if (relayPointStorage === undefined) {
        state.relayPoint = null;
      } else {
        state.relayPoint = relayPointStorage;
      }

      if (carrierStorage === undefined) {
        state.carrier = null;
      } else {
        state.carrier = carrierStorage;
      }
    } catch (error) {}
  },
  clearStore(state, $auth) {
    $auth.$storage.setUniversal(CURRENT_ADDRESS, null);
    $auth.$storage.setUniversal(CURRENT_INVOICE_ADDRESS, null);
    $auth.$storage.setUniversal(DELVERY_OPTION, null);
    $auth.$storage.setUniversal(CARRIER, null);
    $auth.$storage.setUniversal(RELAY_POINT, null);

    state.currentAddress = null;
    state.currentInvoiceAddress = null;
    state.deliveryOption = null;
    state.relayPoint = null;
  },
};
