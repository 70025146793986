import { FavoriteService } from "@/services/FavoriteService.js";

export default {
  data() {
    return {
      favoriteService: new FavoriteService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
    };
  },
  computed: {
    favorite() {
      return this.favoriteService.favorite;
    },
  },
  methods: {
    isInFavorite(product) {
      return this.favorite.Products?.some(
        (item) => Number(item.IdProduct) === Number(product.IdProduct)
      );
    },
    openRightBarFavorite() {
      this.$store.commit("shared/menu/toggle", {
        tabActive: 1,
        open: true,
      });
    },
  },
};
