export const state = () => ({
  customer: null,
})


export const mutations = {
  create(state, {
    user
  }) {

    state.customer = user;
    
  },

  initialiseStore(state, user) {
    state.customer = user
  },

  kill(state, {
    session
  }) {
    state.customer = null;
  },
}
