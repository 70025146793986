import { AddressHelpers } from "@/helpers/AddressHelpers";

export default function (context) {
  // Add the userAgent property to the context
  if (context.$auth.loggedIn) {
    if (context.store.state.user.address.listAddress) {
      if (context.store.state.user.address.listAddress.length === 0) {
        const addressHelpers = new AddressHelpers(context.$http, context.store);
        return addressHelpers.getList();
      }
    }
  }
}
