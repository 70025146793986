export class FavoriteService {
  static ID_FAVORITE = "ID_FAVORITE";

  constructor({ http, auth, store }) {
    this.http = http;
    this.auth = auth;
    this.store = store;
  }

  get favorite() {
    return this.store.state.favorite;
  }
  get idFavorite() {
    let id = null;
    if (this.auth.loggedIn) {
      if (this.auth.user.Customer.IdFavorite) {
        id = this.auth.user.Customer.IdFavorite;
      }
    } else {
      const cookies = this.auth.ctx.$cookies;
      id = cookies.get(FavoriteService.ID_FAVORITE);
    }
    return id;
  }

  get httpApi() {
    let api = this.http.API;
    if (this.auth.loggedIn) {
      api = this.http.API_AUTH;
    }

    return api;
  }

  setIdFavoriteToCookies(idFavorite) {
    const cookies = this.auth.ctx.$cookies;
    cookies.set(FavoriteService.ID_FAVORITE, idFavorite, {
      path: "/",
      maxAge: 60 * 60 * 24,
    });
  }

  removeIdFavoriteFromCookies() {
    const cookies = this.auth.ctx.$cookies;
    cookies.remove(FavoriteService.ID_FAVORITE, {
      path: "/",
      maxAge: 60 * 60 * 24,
    });
  }

  fetchFavorite({ onSuccess, onError } = {}) {
    this.store.dispatch("favorite/fetchFavorite", { onSuccess, onError });
  }
  fetchFavoriteSummary({ onSuccess, onError } = {}) {
    this.store.dispatch("favorite/fetchFavoriteSummary", {
      onSuccess,
      onError,
    });
  }

  clearFavorite() {
    this.store.commit("favorite/clear");
  }
  async getFavoriteAll({ languageIsoCode, currencyIsoCode }) {
    let url = `favorite?CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}&ResponseLevel=complete`;
    let id = this.idFavorite;
    let api = this.httpApi;
    if (id) {
      url += `&IdFavorite=${id}`;
      const response = await api.get(url);
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } else {
      throw new Error(`IdFavorite undefined`);
    }
  }
  async getFavoriteSummary({ languageIsoCode, currencyIsoCode }) {
    let url = `favorite?CurrencyIsoCode=${currencyIsoCode}&LanguageIsoCode=${languageIsoCode}&ResponseLevel=summary`;
    let id = this.idFavorite;
    let api = this.httpApi;
    if (id) {
      url += `&IdFavorite=${id}`;
      const response = await api.get(url);
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } else {
      throw new Error(`IdFavorite undefined`);
    }
  }

  async addToFavorite({ product }) {
    var FormData = require("form-data");
    var formData = new FormData();
    if (this.idFavorite) {
      formData.append("IdFavorite", this.idFavorite);
    }
    formData.append("IdProduct", product.IdProduct);
    // formData.append("Quantity", quantity);
    // formData.append("IdProductAttribute", product.IdProductAttribute);

    try {
      let response = await this.httpApi.post("favorite/add", formData);

      if (response.status === 200) {
        const { data } = response;

        if (!this.auth.loggedIn) {
          this.setIdFavoriteToCookies(data.IdFavorite);
        }
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async removeProductFavorite({ product }) {
    const qs = require("qs");
    let dataQS = qs.stringify({
      IdProduct: product.IdProduct,
      IdFavorite: this.idFavorite,
    });
    try {
      let response = await this.httpApi.delete("favorite/delete", {
        data: dataQS,
      });
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
