import AccountService from "@/services/AccountService.js";

export const state = () => ({
  data: {},
});

export const mutations = {
  setAll(state, { data }) {
    state.data = data;
  },
};

export const actions = {
  fetchOrders({ commit }, { onSuccess = () => {}, onError = () => {} }) {
    const accountService = new AccountService({
      http: this.$http,
      auth: this.$auth,
    });

    accountService
      .getOrderList({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        commit("setAll", { data });
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
};
