export const state = () => ({
  lastBoughtProducts: [],
})


export const mutations = {

  push(state, products) {
    state.lastBoughtProducts = [...state.lastBoughtProducts, ...products]
  },

  init(state, products) {
    state.lastBoughtProducts = products
  },
}
